import { domainKeys } from '@module/list/hooks/domains-setup-queries';
import { useSelectedList, useSelectedListId } from '@module/list/store/useListStore';
import { DeactSettingsFormFields } from '@module/settings/components/list-management/components/DeactSettingsForm';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useDecodedToken } from '../../auth/store/authTokensStore';
import {
  getAccountApiCallsCount,
  getAccountCampaingsCount,
  getAccountDeactSettings,
  getAccountInfo,
  getAccountInvoiceDetails,
  getAccountMailTesterCount,
  getAccountMessagesCount,
  getAccountSettings,
  getMaxEspConnections,
  getMaxListFields,
  updateAccount,
  updateAccountDeactSettings,
  updateProfileImage,
} from '../api/account';
import { getAccountActionsCount, getAccountEmailsSmsCount } from '../api/analytics';
import { RegularUserUpdateAccountPayload } from '../models/models';

export const accountsKeys = {
  account: (id: number) =>
    [
      {
        entity: 'account',
        id,
      },
    ] as const,
  accounts: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'accounts',
        listId,
      },
    ] as const,
  apiCalls: (listId: number | 'all' | undefined, listAccId: number) =>
    [
      {
        entity: 'apiCalls',
        listId,
        listAccId,
      },
    ] as const,
  campaigns: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'campaigns',
        listId,
      },
    ] as const,
  messages: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'messages',
        listId,
      },
    ] as const,
  emailSms: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'emailSms',
        listId,
      },
    ] as const,
  mailTester: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'mailTester',
        listId,
      },
    ] as const,
  maxFieldsList: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'maxFieldsList',
        listId,
      },
    ] as const,
  actionsCount: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'actionsCount',
        listId,
      },
    ] as const,
  espConnCount: (listId: number | 'all' | undefined) =>
    [
      {
        entity: 'espConnCount',
        listId,
      },
    ] as const,
  accountSettings: (accountId: number | undefined) =>
    [
      {
        entity: 'accountSettings',
        accountId,
      },
    ] as const,
  accountInvoiceDetails: (accountId: number | undefined) => [
    {
      entity: 'accountInvoiceDetails',
      accountId,
    },
  ],
};

export const useProfileImageUpdate = () => {
  const queryClient = useQueryClient();
  const list = useSelectedList();

  return useMutation({
    mutationFn: (img: File) => updateProfileImage(list!.account_id, img),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: accountsKeys.account(list!.account_id) }),
  });
};

export const useAccountUpdate = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();
  const list = useSelectedList();
  const { filters } = useTableFilters();

  return useMutation({
    mutationFn: (data: Partial<RegularUserUpdateAccountPayload>) =>
      updateAccount(listId!, list!.account_id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accountsKeys.account(list!.account_id) });
      queryClient.invalidateQueries({ queryKey: domainKeys.filteredList(listId, filters) });
    },
  });
};

export const useAccountInfo = () => {
  const token = useDecodedToken();
  return useQuery({
    queryKey: accountsKeys.account(token?.account_id || -1),
    queryFn: ({ queryKey: [{ id }] }) => getAccountInfo(id),
    select: (data) => data.payload,
    enabled: !!token,
    refetchOnMount: false,
    staleTime: 30_000,
  });
};

export const useAccountEmailDeactSettings = () => {
  const listId = useSelectedListId();

  return useQuery({
    queryKey: accountsKeys.accounts(listId),
    queryFn: ({ queryKey: [{ listId }] }) => getAccountDeactSettings(listId as number),
    select: (data) => data.payload.result,
    enabled: !!listId,
  });
};

export const useDeactSettingsUpdate = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (data: DeactSettingsFormFields) => updateAccountDeactSettings(listId!, data),
  });
};

export const useApiCallsCount = () => {
  const listId = useSelectedListId();
  const list = useSelectedList();
  return useQuery({
    queryKey: accountsKeys.apiCalls(listId, list!.account_id),
    queryFn: () => getAccountApiCallsCount(listId!, list!.account_id),
    enabled: !!listId && !!list?.account_id,
  });
};

export const useCampaignsCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.campaigns(listId),
    queryFn: () => getAccountCampaingsCount(listId!),
    enabled: !!listId,
  });
};

export const useMessagesCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.messages(listId),
    queryFn: () => getAccountMessagesCount(listId!),
    enabled: !!listId,
  });
};

export const useEmailSmsCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.emailSms(listId),
    queryFn: () => getAccountEmailsSmsCount(),
    enabled: !!listId,
  });
};

export const useMailTesterCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.mailTester(listId),
    queryFn: () => getAccountMailTesterCount(listId!),
    enabled: !!listId,
  });
};

export const useMaxFieldList = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.maxFieldsList(listId),
    queryFn: () => getMaxListFields(),
    enabled: !!listId,
  });
};

export const useActionsCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.actionsCount(listId),
    queryFn: () => getAccountActionsCount(),
    enabled: !!listId,
  });
};

export const useEspConnCount = () => {
  const listId = useSelectedListId();
  return useQuery({
    queryKey: accountsKeys.espConnCount(listId),
    queryFn: () => getMaxEspConnections(),
    enabled: !!listId,
  });
};

export const useAccountSettings = (accountId: number | undefined) => {
  return useQuery({
    queryKey: accountsKeys.accountSettings(accountId),
    queryFn: () => getAccountSettings(accountId!),
    enabled: !!accountId,
  });
};

export const useAccountInvoiceDetails = (accountId: number | undefined) => {
  return useQuery({
    queryKey: accountsKeys.accountInvoiceDetails(accountId),
    queryFn: () => getAccountInvoiceDetails(0),
    enabled: !!accountId,
  });
};
