import { useMemo } from 'react';
import { useDecodedToken } from '@module/auth/store/authTokensStore';
import { useUserACL, useUserInfo } from '@module/list/hooks/user-queries';
import { useAllListsSelected } from '@module/list/store/useListStore';
import { isTrue } from '@utils/common';
import { AclPrivileges, isActionAllowed, UserPermissions } from '@utils/permissionsCheck';
import { AxiosError } from 'axios';

import { useAccountInfo } from './account-queries';
import { TokenUserRoles } from '../models/models';

export const useUserPermissions = () => {
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
  } = useUserInfo();
  const {
    data: userAcl,
    isLoading: isUserAclLoading,
    isError: isACLError,
    error: aclError,
  } = useUserACL();

  const { data: account } = useAccountInfo();
  const isAllListsSelected = useAllListsSelected();
  const decodedToken = useDecodedToken();

  const isAdmin =
    user?.payload.is_admin || user?.payload.is_admin_manager || user?.payload.is_admin_manager;
  const userRole = decodedToken?.role as TokenUserRoles;

  const isGeneralUser = userRole === TokenUserRoles.GENERAL_USER;
  const isGeneralUserNoExport = userRole === TokenUserRoles.GENERAL_USER_NO_EXPORT;
  const isSuperAdmin = userRole === TokenUserRoles.SUPER_ADMIN;
  const isReportViewer = userRole === TokenUserRoles.REPORT_VIEWER;
  const isContentWriter = userRole === TokenUserRoles.CONTENT_WRITER;
  const isEmailMarketer = [
    TokenUserRoles.EMAIL_MARKETER,
    TokenUserRoles.EMAIL_MARKETER_NO_LIST_SETTINGS,
  ].includes(userRole);
  const hasNoListSettings =
    userRole === TokenUserRoles.EMAIL_MARKETER_NO_LIST_SETTINGS || isReportViewer;

  const hasPermissionToChoosePlan = !account?.plimus_account_id && !account?.package_id;

  const isLoading = isUserLoading || isUserAclLoading;

  const permissions = useMemo<UserPermissions>(() => {
    const isAdminOrGeneralUser = isAdmin || isGeneralUser;
    const hasAiAccess =
      isAdminOrGeneralUser || isGeneralUserNoExport || isContentWriter || isEmailMarketer;
    return {
      // Dashboard
      hasDashboardViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.ANALYTICS_VIEW], userAcl),
      // List
      hasListChangePermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_VIEW], userAcl),
      hasListImportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_IMPORT], userAcl)),
      hasListExportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.LIST_EXPORT, AclPrivileges.LIST_FIELDS_VIEW], userAcl)),
      // Segments
      hasSegmentsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.SEGMENT_VIEW], userAcl)),
      hasNewSegmentsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.SEGMENT_VIEW, AclPrivileges.SEGMENT_SAVE], userAcl),
      hasEditSegmentViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.SEGMENT_VIEW, AclPrivileges.SEGMENT_SAVE], userAcl),
      // Contacts
      hasContactsManagerViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CONTACT_VIEW], userAcl)),
      hasNewContactsSearchViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.CONTACT_VIEW, AclPrivileges.CONTACT_SAVE], userAcl),
      hasContactSearchReportViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.CONTACT_VIEW, AclPrivileges.CONTACT_SAVE, AclPrivileges.CONTACT_DELETE],
          userAcl,
        ),
      // Form builder
      hasFormBuilderPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_FORM_BUILDER], userAcl)),

      // Conversion points
      hasConversionPointsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CONVERSION_POINT_VIEW], userAcl)),
      hasNewConversionPointsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.CONVERSION_POINT_VIEW, AclPrivileges.CONVERSION_POINT_SAVE],
          userAcl,
        ),
      hasEditConversionPointViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.CONVERSION_POINT_VIEW, AclPrivileges.CONVERSION_POINT_SAVE],
          userAcl,
        ),
      // Automation rules
      hasAutomationRulesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.AUTOMATION_RULE_VIEW], userAcl)),
      hasAutomationRulesViewPermissionWithoutListCheck: isTrue(
        account?.automation_rules?.show_in_ui && account?.automation_rules?.enabled,
      ),
      // List settings
      hasListSettingsViewPermission:
        !isAllListsSelected &&
        !hasNoListSettings &&
        isActionAllowed([AclPrivileges.LIST_FIELDS_VIEW, AclPrivileges.LIST_FIELDS_SAVE], userAcl),
      // AI
      hasAIViewPermission: hasAiAccess || isActionAllowed([AclPrivileges.AI], userAcl),
      // Campaigns
      hasCampaignOverviewViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_VIEW], userAcl),
      hasCampaignsABViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_TEST], userAcl)),
      hasCampaignsCalendarViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_CALENDAR], userAcl),
      hasCampaignsTransactionalViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.CAMPAIGN_VIEW], userAcl)),
      hasEventsAndTriggersViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EVENT_VIEW], userAcl)),
      // Marketing automation (Standalone 'Automation' in menu and sub item 'Automation' in Analytics)
      hasMarketingAutomationPermission:
        !isReportViewer && isTrue(account?.settings?.marketing_automation?.enable),
      // Content
      hasContentEmailsViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VIEW], userAcl)),
      hasContentEmailFormViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VIEW], userAcl),
      hasContentSMSViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.SMS_VIEW], userAcl)),
      hasContentImagesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.IMAGE_VIEW], userAcl)),
      hasContentTemplatesViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.TEMPLATE_VIEW], userAcl)),
      // Analytics
      hasAnalyticsTransactionalViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_TRANSACTIONAL], userAcl),
      hasAnalyticsOverviewViewPermission:
        isAdminOrGeneralUser ||
        (!isReportViewer && isActionAllowed([AclPrivileges.ANALYTICS_VIEW], userAcl)),
      hasAnalyticsAggregateViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_AGGREGATE], userAcl),
      hasAnalyticsEventsViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_EVENT], userAcl),
      hasAnalyticsMatrixViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_MATRIX], userAcl),
      hasAnalyticsContactsActivityViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.CONTACT_VIEW, AclPrivileges.LIST_VIEW], userAcl)),
      hasAnalyticsInsightsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.CONTACT_VIEW], userAcl),
      hasAnalyticsSplitCampaignsViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ANALYTICS_SPLIT], userAcl),
      hasAnalyticsAggregateReportViewPermission:
        !isAllListsSelected &&
        (isAdminOrGeneralUser ||
          isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.CONTACT_VIEW], userAcl)),
      // Vendors
      hasConnectionsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasSMSVendorsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasESPAndSMTPVendorsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      hasEditConnectionViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.ESP_MULTIPLE],
          userAcl,
        ),
      // Validation
      hasValidationIndexViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VALIDATION_VIEW], userAcl),
      hasValidationFormViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VALIDATION_VIEW], userAcl),
      hasValidationSavePermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.EMAIL_VALIDATION_SAVE], userAcl),
      // Account
      hasProfileSettingsViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.ACCOUNT_SETTINGS, AclPrivileges.ACCOUNT_SAVE], userAcl),
      hasProfilePermission:
        isAdminOrGeneralUser ||
        userRole === TokenUserRoles.EMAIL_MARKETER ||
        isActionAllowed([AclPrivileges.ACCOUNT_SETTINGS, AclPrivileges.ACCOUNT_SAVE], userAcl),
      hasAccountSpecsViewPermission:
        (isAdminOrGeneralUser || isActionAllowed([AclPrivileges.ACCOUNT_SAVE], userAcl)) &&
        decodedToken?.role !== TokenUserRoles.GENERAL_USER &&
        decodedToken?.role !== TokenUserRoles.GENERAL_USER_NO_EXPORT &&
        !!account?.package_id,
      hasAccountSettingsViewPermission:
        isAdminOrGeneralUser || userRole === TokenUserRoles.EMAIL_MARKETER,
      hasAccountConfigurationsPermission: !!isAdmin,
      hasImageTrackingViewPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.DOMAIN_VIEW], userAcl),
      hasDomainRoutingViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed(
          [AclPrivileges.ESP_VIEW, AclPrivileges.ESP_SAVE, AclPrivileges.SEGMENT_VIEW],
          userAcl,
        ),
      hasUserManagementViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.USERS_VIEW, AclPrivileges.USERS_SAVE], userAcl),
      hasListManagementViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LIST_VIEW, AclPrivileges.LIST_SAVE], userAcl),
      hasChoosePlanPermission: hasPermissionToChoosePlan,
      // NOP
      hasNopIndexViewPermission: isSuperAdmin,
      // Welcome page
      hasSetupWelcomeViewPermission: isAdminOrGeneralUser,
      // List settings tabs
      hasListPostbacksPermission:
        isAdminOrGeneralUser || isActionAllowed([AclPrivileges.LIST_POSTBACKS], userAcl),
      hasLinksGropusViewPermission:
        isAdminOrGeneralUser ||
        isActionAllowed([AclPrivileges.LINK_GROUPS_VIEW, AclPrivileges.LINK_GROUPS_SAVE], userAcl),
    };
  }, [
    isAdmin,
    isGeneralUser,
    isGeneralUserNoExport,
    isContentWriter,
    isEmailMarketer,
    userAcl,
    isAllListsSelected,
    account?.automation_rules?.show_in_ui,
    account?.automation_rules?.enabled,
    account?.settings?.marketing_automation?.enable,
    account?.package_id,
    hasNoListSettings,
    isReportViewer,
    userRole,
    decodedToken?.role,
    hasPermissionToChoosePlan,
    isSuperAdmin,
  ]);

  return {
    user,
    account,
    isLoading,
    permissions,
    userRole,
    isError: isUserError || isACLError,
    error: (userError || aclError) as AxiosError | null,
  };
};
