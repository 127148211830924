import { FC, useMemo } from 'react';
import { CheckboxField } from '@ui/CheckboxField';
import { Icon } from '@ui/Icon/Icon';
import { Dropdown } from 'react-bootstrap';

import styles from '../../../../ui/table/controls/ControlWrapper.module.scss';
import { useTableDataContext } from '../../store/useTableColumns';

export const TableColumnHidingFilter: FC = () => {
  const columns = useTableDataContext((state) => state.columns);

  const columnsToOmit = columns.filter((c) => c.omit).map((c) => c.accessorField);
  const { hideColumn, showColumn, showAllColumns, resetToDefault } = useTableDataContext(
    (state) => state.actions,
  );

  const handleCheckboxChange = (column: (typeof columns)[0]['accessorField'], checked: boolean) => {
    if (checked) {
      showColumn(column);
    } else {
      hideColumn(column);
    }
  };

  const columnsToWorkWith = useMemo(
    () =>
      columns
        .filter((column) => !column.hideFromTableHidingComponent)
        .sort((a, b) => Number(a?.order || 0) - Number(b?.order || 0)),
    [columns],
  );

  return (
    <Dropdown drop="down" autoClose="outside" align="end">
      <Dropdown.Toggle as="div" className={styles.controlWrapper}>
        <Icon name="columns" stroke="secondary" width={22} height={22} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          minWidth: '240px',
          borderRadius: '8px',
          border: '1px solid var(--ongage-gray-200)',
          background: 'var(--bs-white)',
          boxShadow: 'var(--ongage-box-shadow-lg)',
        }}
      >
        <Dropdown.Item as="div" onClick={resetToDefault}>
          <div className="d-flex flex-row gap-2 align-items-center px-2 py-1 cursor-pointer">
            <span className="w-20px h-20px ms-1">
              <Icon name="refresh" stroke="secondary" width={16} height={16} />
            </span>
            <span className="fs-6 fw-semibold text-gray-700">Reset</span>
          </div>
        </Dropdown.Item>

        <Dropdown.Item as="div" onClick={showAllColumns}>
          <div className="d-flex flex-row gap-2 align-items-center px-2 py-1 cursor-pointer">
            <span className="w-20px h-20px ms-1">
              <Icon name="checkDone" stroke="secondary" width={16} height={16} />
            </span>
            <span className="fs-6 fw-semibold text-gray-700">Select All</span>
          </div>
        </Dropdown.Item>

        <Dropdown.Divider />

        {columnsToWorkWith.map((column, index) => (
          <Dropdown.Item key={index} as="div">
            <CheckboxField
              name="columns"
              className="w-100 px-2 py-1"
              label={!column.hideFromTableHidingComponent ? column.exportColumnName : ''}
              checked={!columnsToOmit?.includes((column.id || column.accessorField)?.toString())}
              onChange={(e) =>
                handleCheckboxChange(
                  (column.id || column.accessorField)?.toString(),
                  e.target.checked,
                )
              }
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
