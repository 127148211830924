import React, {
  Children,
  FC,
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import clsx from 'clsx';
import BSOverlayTrigger, { OverlayDelay } from 'react-bootstrap/OverlayTrigger';
import BSTooltip from 'react-bootstrap/Tooltip';

import styles from './Tooltip.module.scss';

import type { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

type TooltipPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type TriggerEvent = 'hover' | 'focus' | 'click';

type TooltipColorTheme = 'light' | 'dark';

interface TooltipProps {
  title?: ReactNode;
  description?: ReactNode;
  placement?: TooltipPlacement;
  arrowHidden?: boolean;
  show?: boolean;
  onToggle?: () => void;
  delay?: OverlayDelay;
  triggerEvents?: TriggerEvent[];
  colorTheme?: TooltipColorTheme;
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  hidden?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  title,
  description,
  delay,
  show,
  onToggle,
  placement = 'top-start',
  arrowHidden = true,
  triggerEvents = ['hover', 'focus'],
  colorTheme = 'dark',
  hidden = false,
  children,
}) => {
  if (!Children.only(children) || children.type === Fragment) {
    throw new Error(
      'Tooltip only accepts a single child element, not an array of children or Fragment',
    );
  }

  const renderTooltip = useCallback(
    (props: OverlayInjectedProps) => {
      const arrowStyle: React.CSSProperties | undefined = arrowHidden
        ? {
            display: 'none',
          }
        : undefined;

      return (
        <BSTooltip
          {...props}
          className={clsx(props.className, styles.tooltip, {
            [styles.dark]: colorTheme === 'dark',
          })}
          arrowProps={{
            ...props.arrowProps,
            style: {
              ...props.arrowProps?.style,
              ...arrowStyle,
            },
          }}
          hidden={hidden}
        >
          <div className={clsx('fs-7', styles.tooltipInner)}>
            {title && <span className={styles.tooltipTitle}>{title}</span>}
            {description && <span className={styles.tooltipDescription}>{description}</span>}
          </div>
        </BSTooltip>
      );
    },
    [arrowHidden, description, title, colorTheme, hidden],
  );

  return (
    <BSOverlayTrigger
      flip
      placement={placement}
      overlay={renderTooltip}
      show={show}
      delay={delay}
      onToggle={onToggle}
      trigger={triggerEvents}
    >
      {children}
    </BSOverlayTrigger>
  );
};
