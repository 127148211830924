import SingleStackIcon from './custom/1-stack.svg?react';
import DoubleStackIcon from './custom/2-stack.svg?react';
import TripleStackIcon from './custom/3-stack.svg?react';
import ArrowDownIcon from './custom/aarrow-down.svg?react';
import AlertCircleIcon from './custom/alert-circle.svg?react';
import AlertTriangleIcon from './custom/alert-triangle.svg?react';
import AnnotationInfoIcon from './custom/annotation-info.svg?react';
import ArchiveListIcon from './custom/archive-list.svg?react';
import ArrowRight from './custom/arrow-narrow-right.svg?react';
import BackArrowIcon from './custom/back-arrow.svg?react';
import BackgroundCirclesPattern from './custom/background-circles-pattern.svg?react';
import BarChartIcon from './custom/bar-chart.svg?react';
import BeakerIcon from './custom/beaker-02.svg?react';
import BellIcon from './custom/bell-01.svg?react';
import BellDotIcon from './custom/bell-dot.svg?react';
import BookClosedIcon from './custom/book-closed.svg?react';
import CalcIcon from './custom/calc.svg?react';
import CalendarIcon from './custom/calendar.svg?react';
import CheckCircleIcon from './custom/check-circle.svg?react';
import CheckVerifiedIcon from './custom/check-verified-02.svg?react';
import CheckIcon from './custom/check.svg?react';
import ChevronDownIcon from './custom/chevron-down.svg?react';
import ChevronLeftIcon from './custom/chevron-left.svg?react';
import ChevronRightIcon from './custom/chevron-right-1.svg?react';
import ChevronUpIcon from './custom/chevron-up.svg?react';
import ClockIcon from './custom/clock.svg?react';
import ColumnsIcon from './custom/columns-03.svg?react';
import CommandIcon from './custom/command.svg?react';
import Confetti from './custom/confetti.svg?react';
import ContainerIcon from './custom/container.svg?react';
import ContrastIcon from './custom/contrast-01.svg?react';
import CopyListIcon from './custom/copy-list.svg?react';
import CopyIcon from './custom/copy.svg?react';
import CropIcon from './custom/crop-02.svg?react';
import CursorBoxIcon from './custom/cursor-box.svg?react';
import CursorClickIcon from './custom/cursor-click-02.svg?react';
import CustomerSupportIcon from './custom/customer-support.svg?react';
import DatabaseIcon from './custom/database-01.svg?react';
import DataFlowVertIcon from './custom/dataflow-2.svg?react';
import DataFlowIcon from './custom/dataflow.svg?react';
import DislikeIcon from './custom/dislike.svg?react';
import DotIcon from './custom/dot.svg?react';
import DotsVerticalIcon from './custom/dots-vertical.svg?react';
import DownloadCloudIcon from './custom/download-cloud-01.svg?react';
import AppStoreIcon from './custom/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg?react';
import ErrorFileIcon from './custom/error-log.svg?react';
import ExportCsvIcon from './custom/export-csv.svg?react';
import EyeOffIcon from './custom/eye-off.svg?react';
import EyeIcon from './custom/eye.svg?react';
import FileIcon from './custom/file-02.svg?react';
import FileCodeIcon from './custom/file-code-02.svg?react';
import FileSearchIcon from './custom/file-search-02.svg?react';
import FileShield from './custom/file-shield.svg?react';
import FileTypeIcon from './custom/file-type.svg?react';
import FiltersIcon from './custom/filters.svg?react';
import GlobeIcon from './custom/globe.svg?react';
import GooglePlayIcon from './custom/Google_Play-Badge-Logo.wine.svg?react';
import HeadphonesIcon from './custom/headphones-01.svg?react';
import HeadphonesBigIcon from './custom/headphones-02.svg?react';
import HelpCircleIcon from './custom/help-circle.svg?react';
import InfoIcon from './custom/info-icon.svg?react';
import IntersectCircleIcon from './custom/intersect-circle.svg?react';
import KeyIcon from './custom/key.svg?react';
import LayoutLeft from './custom/layout-left.svg?react';
import LifeBuoyIcon from './custom/life-buoy.svg?react';
import LineChartIcon from './custom/line-chart.svg?react';
import LinkIcon from './custom/link.svg?react';
import ListIcon from './custom/list.svg?react';
import LockIcon from './custom/lock.svg?react';
import LogoutIcon from './custom/log-out-01.svg?react';
import MailIcon from './custom/mail-02.svg?react';
import MailOpenIcon from './custom/mail-05.svg?react';
import MessageAcceptedIcon from './custom/message-accepted.svg?react';
import MessageAlertIcon from './custom/message-alert.svg?react';
import MessageDotsCircleIcon from './custom/message-dots-circle.svg?react';
import MsgQuestionCircleIcon from './custom/message-question-circle.svg?react';
import MessageSquareIcon from './custom/message-text-square-01.svg?react';
import MinusCircleIcon from './custom/minus-circle.svg?react';
import NotificationTextIcon from './custom/notification-text.svg?react';
import NotificationsEmptyState from './custom/notifications-empty-state.svg?react';
import NumbersIcon from './custom/numbers.svg?react';
import PencilIcon from './custom/pencil.svg?react';
import PieChartIcon from './custom/pie-chart.svg?react';
import PinIcon from './custom/pin.svg?react';
import PlayIcon from './custom/play.svg?react';
import PlugIcon from './custom/plug.svg?react';
import PlusCircleIcon from './custom/plus-circle.svg?react';
import PlusIcon from './custom/plus.svg?react';
import PrintIcon from './custom/print.svg?react';
import RefreshIcon from './custom/refresh.svg?react';
import RepeatIcon from './custom/repeat-03.svg?react';
import RestoreIcon from './custom/restore.svg?react';
import SearchLgIcon from './custom/search-lg.svg?react';
import SendIcon from './custom/send-01.svg?react';
import SettingsGearIcon from './custom/settings-gear.svg?react';
import SettingsIcon from './custom/settings.svg?react';
import Share from './custom/share.svg?react';
import ShieldOffIcon from './custom/shield-off.svg?react';
import ShieldIcon from './custom/shield.svg?react';
import SparklesIcon from './custom/sparkles.svg?react';
import SquareIcon from './custom/square.svg?react';
import StarIcon from './custom/star-01.svg?react';
import TagIcon from './custom/tag.svg?react';
import TextInputIcon from './custom/text-input.svg?react';
import ToolIcon from './custom/tool.svg?react';
import TrashIcon from './custom/trash.svg?react';
import UploadCloudIcon from './custom/uploadCloud.svg?react';
import UserIcon from './custom/user-01.svg?react';
import UserCheckIcon from './custom/user-check.svg?react';
import UserPlusIcon from './custom/user-plus-01.svg?react';
import UsersArrowIcon from './custom/users-arrow.svg?react';
import UsersCrossIcon from './custom/users-cross.svg?react';
import UsersPlusIcon from './custom/users-plus.svg?react';
import UsersIcon from './custom/users.svg?react';
import XCircleIcon from './custom/x-circle.svg?react';
import XIcon from './custom/x.svg?react';
import ZapIcon from './custom/zap.svg?react';
import DotsGrid from './custom/dots-grid.svg?react';
import CheckDone from './custom/check-done-1.svg?react';

export {
  DataFlowIcon,
  CheckDone,
  PieChartIcon,
  PlugIcon,
  MessageAcceptedIcon,
  SparklesIcon,
  LifeBuoyIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  AppStoreIcon,
  GooglePlayIcon,
  SearchLgIcon,
  DownloadCloudIcon,
  PlusIcon,
  XCircleIcon,
  CheckCircleIcon,
  HelpCircleIcon,
  StarIcon,
  ColumnsIcon,
  UserPlusIcon,
  AlertCircleIcon,
  AlertTriangleIcon,
  XIcon,
  GlobeIcon,
  DotsVerticalIcon,
  DotsGrid,
  TrashIcon,
  PencilIcon,
  SquareIcon,
  CopyIcon,
  SettingsIcon,
  CalcIcon,
  TagIcon,
  ErrorFileIcon,
  LinkIcon,
  PlayIcon,
  LockIcon,
  BackgroundCirclesPattern,
  CalendarIcon,
  LayoutLeft,
  UserIcon,
  LogoutIcon,
  ContainerIcon,
  FileSearchIcon,
  SettingsGearIcon,
  BellIcon,
  ListIcon,
  SendIcon,
  MailIcon,
  HeadphonesIcon,
  CheckIcon,
  DotIcon,
  RepeatIcon,
  TextInputIcon,
  FileShield,
  ShieldIcon,
  ShieldOffIcon,
  UploadCloudIcon,
  SingleStackIcon,
  DoubleStackIcon,
  TripleStackIcon,
  NumbersIcon,
  RefreshIcon,
  UsersIcon,
  UsersCrossIcon,
  DislikeIcon,
  UsersArrowIcon,
  PrintIcon,
  LineChartIcon,
  BarChartIcon,
  MessageAlertIcon,
  KeyIcon,
  BackArrowIcon,
  EyeIcon,
  EyeOffIcon,
  FiltersIcon,
  InfoIcon,
  ToolIcon,
  ArchiveListIcon,
  CopyListIcon,
  ExportCsvIcon,
  BookClosedIcon,
  CheckVerifiedIcon,
  MsgQuestionCircleIcon,
  CustomerSupportIcon,
  ArrowRight,
  Share,
  Confetti,
  RestoreIcon,
  MessageDotsCircleIcon,
  ClockIcon,
  FileTypeIcon,
  ZapIcon,
  DataFlowVertIcon,
  BeakerIcon,
  MailOpenIcon,
  MessageSquareIcon,
  FileIcon,
  FileCodeIcon,
  IntersectCircleIcon,
  CursorBoxIcon,
  CommandIcon,
  DatabaseIcon,
  ContrastIcon,
  CursorClickIcon,
  PinIcon,
  UsersPlusIcon,
  CropIcon,
  UserCheckIcon,
  HeadphonesBigIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  BellDotIcon,
  NotificationsEmptyState,
  NotificationTextIcon,
  AnnotationInfoIcon,
};

export const ICONS = {
  dataflow: DataFlowIcon,
  checkDone: CheckDone,
  dotsGrid: DotsGrid,
  pieChart: PieChartIcon,
  plug: PlugIcon,
  messageAccepted: MessageAcceptedIcon,
  sparkles: SparklesIcon,
  lifeBuoy: LifeBuoyIcon,
  arrowDown: ArrowDownIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  chevronRight: ChevronRightIcon,
  chevronLeft: ChevronLeftIcon,
  searchLg: SearchLgIcon,
  downloadCloud: DownloadCloudIcon,
  plus: PlusIcon,
  xCircle: XCircleIcon,
  checkCircle: CheckCircleIcon,
  helpCircle: HelpCircleIcon,
  star: StarIcon,
  columns: ColumnsIcon,
  userPlus: UserPlusIcon,
  alertCircle: AlertCircleIcon,
  alertTriangle: AlertTriangleIcon,
  xNoCircle: XIcon,
  globe: GlobeIcon,
  lock: LockIcon,
  backgroundCirclesPattern: BackgroundCirclesPattern,
  dotsVertical: DotsVerticalIcon,
  trash: TrashIcon,
  pencil: PencilIcon,
  square: SquareIcon,
  copy: CopyIcon,
  settings: SettingsIcon,
  calc: CalcIcon,
  tag: TagIcon,
  errorFile: ErrorFileIcon,
  link: LinkIcon,
  play: PlayIcon,
  calendar: CalendarIcon,
  layoutLeft: LayoutLeft,
  user: UserIcon,
  logout: LogoutIcon,
  container: ContainerIcon,
  fileSearch: FileSearchIcon,
  settingsGear: SettingsGearIcon,
  bell: BellIcon,
  list: ListIcon,
  send: SendIcon,
  mail: MailIcon,
  headphones: HeadphonesIcon,
  check: CheckIcon,
  dot: DotIcon,
  repeat: RepeatIcon,
  textInput: TextInputIcon,
  fileShield: FileShield,
  shield: ShieldIcon,
  shieldOff: ShieldOffIcon,
  uploadCloud: UploadCloudIcon,
  singleStack: SingleStackIcon,
  doubleStack: DoubleStackIcon,
  tripleStack: TripleStackIcon,
  numbers: NumbersIcon,
  refresh: RefreshIcon,
  users: UsersIcon,
  usersCross: UsersCrossIcon,
  dislike: DislikeIcon,
  usersArrow: UsersArrowIcon,
  print: PrintIcon,
  lineChart: LineChartIcon,
  barChart: BarChartIcon,
  messageAlert: MessageAlertIcon,
  key: KeyIcon,
  backArrow: BackArrowIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  filters: FiltersIcon,
  infoIcon: InfoIcon,
  toolIcon: ToolIcon,
  copyList: CopyListIcon,
  archiveList: ArchiveListIcon,
  exportCsv: ExportCsvIcon,
  checkVerified: CheckVerifiedIcon,
  bookClosed: BookClosedIcon,
  msgQuestionCircle: MsgQuestionCircleIcon,
  customerSupport: CustomerSupportIcon,
  arrowRight: ArrowRight,
  share: Share,
  confetti: Confetti,
  restore: RestoreIcon,
  messageDotsCircle: MessageDotsCircleIcon,
  clock: ClockIcon,
  zap: ZapIcon,
  dataflowVert: DataFlowVertIcon,
  beaker: BeakerIcon,
  mailOpen: MailOpenIcon,
  messageSquare: MessageSquareIcon,
  file: FileIcon,
  fileCode: FileCodeIcon,
  intersectCircle: IntersectCircleIcon,
  cursorBox: CursorBoxIcon,
  command: CommandIcon,
  database: DatabaseIcon,
  contrast: ContrastIcon,
  cursorClick: CursorClickIcon,
  pin: PinIcon,
  usersPlus: UsersPlusIcon,
  crop: CropIcon,
  userCheck: UserCheckIcon,
  headphonesBig: HeadphonesBigIcon,
  minusCircle: MinusCircleIcon,
  plusCircle: PlusCircleIcon,
  bellDot: BellDotIcon,
  notificationsEmptyState: NotificationsEmptyState,
  notificationText: NotificationTextIcon,
  annotationInfo: AnnotationInfoIcon,
};
