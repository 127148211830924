import { FC, useEffect } from 'react';
import { ErrorFallback } from '@module/errors/components/ErrorFallback';
import { usePageTitleNameByLocation } from '@module/shared/hooks/usePageTitleNameByLocation';
import { IframeMessageProvider } from '@providers/IframeMessageProvider';
import posthog from 'posthog-js';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router';

import { useSetupAxios } from '../modules/auth';

const Page: FC = () => {
  useSetupAxios();
  const pageTitle = usePageTitleNameByLocation();

  const location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
    document.title = pageTitle;
  }, [location, pageTitle]);

  return (
    <ErrorBoundary fallbackRender={({ error }) => <ErrorFallback error={error} />}>
      <Outlet />
    </ErrorBoundary>
  );
};

const RootPage = () => {
  return (
    <IframeMessageProvider>
      <Page />
    </IframeMessageProvider>
  );
};

export default RootPage;
