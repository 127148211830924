// Url can be a plain url or with default search params, params will be added to main url
export const buildUrl = (
  url: string,
  params: Record<string, string | number | Array<unknown> | boolean | undefined>,
) => {
  const u = new URL(url);

  // Append parameters while skipping undefined values
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      u.searchParams.append(key, value.toString());
    }
  });

  return u;
};
