import { useLogout } from '@pages/auth/logout/Logout';
import { ActionType, useIframeMessage } from '@providers/IframeMessageProvider';
import axios, { AxiosError } from 'axios';
import { usePostHog } from 'posthog-js/react';

import { AUTH_URLS, refreshToken as refreshTokenRequest } from './api';
import {
  useAccessToken,
  useAuthTokensActions,
  useRefreshToken,
  useRememberMe,
} from './store/authTokensStore';
import { useUserInfo } from '@module/list/hooks/user-queries';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { useMemo } from 'react';

let tokenIsRefreshing = false;
const refreshAndRetryQueue: CallableFunction[] = [];

const urlContainsAuthUrl = (url: string | undefined): boolean => {
  if (!url) return false;
  return Object.values(AUTH_URLS).some((authUrl) => url.includes(authUrl));
};

const subscribeTokenRefresh = (cb) => {
  refreshAndRetryQueue.push(cb);
};

const onRefreshed = (token: string) => {
  refreshAndRetryQueue.map((cb) => cb(token));
};

export function useSetupAxios() {
  const accessToken = useAccessToken();
  const refreshToken = useRefreshToken();
  const rememberMe = useRememberMe();
  const { setTokens } = useAuthTokensActions();
  const posthog = usePostHog();
  const logout = useLogout();
  const { postMessage } = useIframeMessage();

  const { data: user } = useUserInfo();
  const { data: account } = useAccountInfo();

  const timezone = useMemo(() => user?.payload.timezone || account?.timezone, [user, account]);

  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers['X-Request-Ir'] = true;
  axios.interceptors.request.use(
    (config) => {
      if (accessToken && !config.headers.Authorization) {
        config.headers.setAuthorization(`Bearer ${accessToken}`, true);
      }

      return config;
    },
    (err) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    (response) => response, // ({ ...response, data: decodeStringsInObject(response.data) }),
    async (error: AxiosError) => {
      const originalRequest = error.config;
      if (!originalRequest?.headers) {
        return Promise.reject(error);
      }

      if (
        error.response?.status === 401 &&
        accessToken &&
        refreshToken &&
        !urlContainsAuthUrl(originalRequest.url)
      ) {
        if (!tokenIsRefreshing) {
          tokenIsRefreshing = true;
          // Refresh the access token
          const oauthStorage = localStorage.getItem('oauth');
          const test = JSON.parse(oauthStorage || '{}');
          refreshTokenRequest(test.state.refreshToken, rememberMe)
            .then(({ data }) => {
              setTokens(data.access_token, data.refresh_token, posthog, rememberMe);
              onRefreshed(data.access_token);
              // message to iframe
              postMessage({
                action: ActionType.ACCESS_TOKEN_RENEWED,
                data: { access_token: data.access_token, timezone },
                version: 1,
              });
            })
            .catch(logout)
            .finally(() => {
              tokenIsRefreshing = false;
              refreshAndRetryQueue.length = 0;
            });
        }

        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            // replace the expired token and retry
            originalRequest.headers.setAuthorization(`Bearer ${token}`, true);
            resolve(axios(originalRequest));
          });
        });
      } else {
        return Promise.reject(error);
      }
    },
  );
}
