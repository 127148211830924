import { FC, PropsWithChildren } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { useUserPermissions } from '@module/shared/hooks/useRoutePermissionsCheck';
import { useWrappedTo } from '@routing/to';
import { ROUTES } from '@utils/routes';
import { Navigate } from 'react-router';

import { LayoutSplashScreen } from '../../../../_metronic/layout/core';

export const AlreadyAuthenticatedUserGuard: FC<PropsWithChildren> = ({ children }) => {
  const accessToken = useAccessToken();
  const { isLoading, isError } = useUserPermissions();
  const to = useWrappedTo();

  if (!accessToken) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LayoutSplashScreen />;
  }

  if (!isError && accessToken) {
    return <Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />;
  }

  return <>{children}</>;
};
