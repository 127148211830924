import { useParams } from 'react-router';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { List } from './models';

export interface SelectedListState {
  list: List | undefined;
  allListsSelected: boolean;

  actions: {
    setList: (list: List | undefined) => void;
    selectAllLists: () => void;
  };
}

export const useSelectedListStore = create<SelectedListState>()(
  persist(
    (set) => ({
      list: undefined,
      allListsSelected: false,
      actions: {
        setList: (list) => set({ list, allListsSelected: false }),
        selectAllLists: () => set({ allListsSelected: true }),
      },
    }),
    {
      name: 'list',
      partialize: (state) => ({ list: state.list, allListsSelected: state.allListsSelected }),
    },
  ),
);

export const useSelectedList = () => useSelectedListStore((state) => state.list);
export const useAllListsSelected = () => useSelectedListStore((state) => state.allListsSelected);
export const useSelectedListId = (): number | 'all' | undefined => {
  const allListsSelected = useAllListsSelected();
  const list = useSelectedList();

  const { listId } = useParams();
  if (listId) {
    return listId === 'all' ? 'all' : Number(listId);
  }

  return allListsSelected ? 'all' : list?.id;
};

export const useSelectedListActions = () => useSelectedListStore((state) => state.actions);
