import { lazy } from 'react';
import { SuspensedWrapper } from '@routing/SuspensedWrapper';
import { Route, Routes } from 'react-router';

import { ErrorsLayout } from './ErrorsLayout';

const Error404 = lazy(() => import('./components/Error404'));
const Error500 = lazy(() => import('./components/Error500'));

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route
        path="404"
        element={
          <SuspensedWrapper>
            <Error404 />
          </SuspensedWrapper>
        }
      />
      <Route
        path="500"
        element={
          <SuspensedWrapper>
            <Error500 />
          </SuspensedWrapper>
        }
      />
      <Route
        index
        element={
          <SuspensedWrapper>
            <Error404 />
          </SuspensedWrapper>
        }
      />
    </Route>
  </Routes>
);

export { ErrorsPage };
