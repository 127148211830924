import { FC, useCallback, useEffect, useState } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { useSelectedListId } from '@module/list/store/useListStore';
import { ActionType } from '@providers/IframeMessageProvider';

import config from '../../app-config';

interface IframePageProps {
  src:
    | string
    | (({ listId, accessToken }: { listId?: string | number; accessToken: string }) => string);
  isLegacyAppPage?: boolean;
}

const backgroundStyles = {
  width: '100%',
  minHeight: '100vh',
  background: 'url("/media/logos/default.svg") no-repeat center center',
};

export const IframePage: FC<IframePageProps> = ({ src }) => {
  const [loadedAt, reload] = useState(Date.now());

  const accessToken = useAccessToken();
  const listId = useSelectedListId();
  const iframeSrc =
    typeof src === 'string'
      ? constructDefaultIframeSrc({
          src,
          listId,
        })
      : src({ listId, accessToken });

  const handleCompleteLoading = useCallback((event: MessageEvent) => {
    if (event.data.action === ActionType.RELOAD_IFRAME) {
      reload(Date.now());
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleCompleteLoading);

    return () => {
      window.removeEventListener('message', handleCompleteLoading);
    };
  }, [handleCompleteLoading]);

  return <iframe key={`${src}-${loadedAt}`} src={iframeSrc} style={backgroundStyles} />;
};

function constructDefaultIframeSrc({
  src,
  listId,
}: {
  src: string;
  listId?: string | number;
}): string {
  const base = config.iframeUrl;
  const srcUrl = new URL(`/${listId ?? ''}${src}`, base);

  return srcUrl.toString();
}
