import { useCallback } from 'react';
import { useSelectedListId } from '@module/list/store/useListStore';
import { generatePath, PathParam } from 'react-router';

export type ListIdPath = `/:listId${string}`;

type Params<T extends ListIdPath> = {
  [key in PathParam<T>]: string | null;
};

export const useWrappedTo = () => {
  const listId = useSelectedListId();
  return useCallback(
    <T extends ListIdPath>(
      path: T,
      params: Omit<Params<T>, 'listId'> = {} as never,
      overriddenListId?: number | 'all',
    ) =>
      // I can't find quick solution for correct type resolution, maybe it's not possible in current ts version
      // eslint-disable-next-line
      // @ts-ignore
      generatePath(path, { listId: overriddenListId?.toString() || listId?.toString(), ...params }),
    [listId],
  );
};
