import { User } from '@module/list/store/models';
import { UserAclResponseType } from '@module/shared/models/FapiResponse';

export const isAdmin = (user: User | undefined) => {
  if (!user) {
    return false;
  }

  if (user.is_admin || user.is_admin_manager || user.is_admin_manager) {
    return true;
  }
};

export enum Action {
  DELETE = 'delete',
  EDIT = 'edit',
}

// 'currentAccount' is the account attempting to perform the deletion
// 'user' is the target user to be deleted
export const permissionsCheck = (currentAccount: User, user: User, action: Action): boolean => {
  // 'self' is a boolean indicating if the user is trying to delete themselves
  const self = currentAccount.id === user.id;

  // User can edit themselves
  if (self && action === Action.EDIT) {
    return true;
  }
  // User can't delete themselves
  if (self && action === Action.DELETE) {
    return false;
  }
  // Super admins can delete anything but themselves
  if (currentAccount.is_super_admin) {
    return true;
  }
  // Admin managers can delete anything but super admins
  if (currentAccount.is_admin_manager && !user.is_super_admin) {
    return true;
  }
  // Creators can delete anything but super admins and admin managers
  if (currentAccount.is_creator && !user.is_super_admin && !user.is_admin_manager) {
    return true;
  }
  // Admins can can edit and delete other admins
  if (currentAccount.is_admin && !user.is_super_admin && !user.is_admin_manager) {
    return true;
  }
  // By default, do not allow deletion
  return false;
};

export const isActionAllowed = (
  permissionKeys: AclPrivileges[],
  permissions: UserAclResponseType | undefined,
): boolean => {
  if (!permissions?.restricted_permission) {
    return true;
  } else {
    return permissionKeys.every((key) => !!permissions.privileges[key]);
  }
};

export type Permissions = {
  [key in AclPrivileges]?: boolean;
};

export enum AclPrivileges {
  CAMPAIGN_VIEW = 'campaign/view',
  CAMPAIGN_SAVE = 'campaign/save',
  CAMPAIGN_DELETE = 'campaign/delete',
  CAMPAIGN_TEST = 'campaign/test',
  CAMPAIGN_SCHEDULE = 'campaign/schedule',
  CAMPAIGN_CALENDAR = 'campaign/calendar',
  EMAIL_VIEW = 'email/view',
  EMAIL_SAVE = 'email/save',
  EMAIL_DELETE = 'email/delete',
  EMAIL_TEST = 'email/test',
  SPLIT_CAMPAIGN_VIEW = 'split_campaign/view',
  SPLIT_CAMPAIGN_SAVE = 'split_campaign/save',
  SPLIT_CAMPAIGN_DELETE = 'split_campaign/delete',
  SPLIT_CAMPAIGN_SCHEDULE = 'split_campaign/schedule',
  EVENT_VIEW = 'event/view',
  EVENT_SAVE = 'event/save',
  EVENT_DELETE = 'event/delete',
  EVENT_ACTIVATE = 'event/activate',
  LIST_VIEW = 'list/view',
  LIST_SAVE = 'list/save',
  LIST_DELETE = 'list/delete',
  LIST_FIELDS_VIEW = 'list/fields_view',
  LIST_IMPORT = 'list/import',
  LIST_EXPORT = 'list/export',
  CONTACT_VIEW = 'contact/view',
  CONTACT_SAVE = 'contact/save',
  CONTACT_DELETE = 'contact/delete',
  SEGMENT_VIEW = 'segment/view',
  SEGMENT_SAVE = 'segment/save',
  SEGMENT_DELETE = 'segment/delete',
  TEMPLATE_VIEW = 'template/view',
  TEMPLATE_SAVE = 'template/save',
  TEMPLATE_DELETE = 'template/delete',
  TEMPLATE_FOLDERS = 'template/folders',
  IMAGE_VIEW = 'image/view',
  IMAGE_SAVE = 'image/save',
  IMAGE_DELETE = 'image/delete',
  IMAGE_FOLDERS = 'image/folders',
  ANALYTICS_VIEW = 'analytics/view',
  ESP_VIEW = 'esp/view',
  ESP_SAVE = 'esp/save',
  ESP_DELETE = 'esp/delete',
  DOMAIN_VIEW = 'domain/view',
  DOMAIN_SAVE = 'domain/save',
  DOMAIN_DELETE = 'domain/delete',
  ACCOUNT_SAVE = 'account/save',
  LIST_FIELDS_SAVE = 'list/fields_save',
  SMS_VIEW = 'sms/view',
  SMS_SAVE = 'sms/save',
  SMS_DELETE = 'sms/delete',
  ESP_MANAGE_CREDENTIALS = 'esp/manage_credentials',
  CONVERSION_POINT_VIEW = 'conversion_point/view',
  CONVERSION_POINT_SAVE = 'conversion_point/save',
  ESP_MULTIPLE = 'esp/multiple',
  LINK_GROUPS_VIEW = 'link_groups/view',
  LINK_GROUPS_SAVE = 'link_groups/save',
  LINK_GROUPS_DELETE = 'link_groups/delete',
  LIST_POSTBACKS = 'list/postbacks',
  LIST_FORM_BUILDER = 'list/form_builder',
  ACCOUNT_SETTINGS = 'account/settings',
  ANALYTICS_AGGREGATE = 'analytics/aggregate',
  ANALYTICS_MATRIX = 'analytics/matrix',
  ANALYTICS_SPLIT = 'analytics/split',
  ANALYTICS_EVENT = 'analytics/event',
  ANALYTICS_TRANSACTIONAL = 'analytics/transactional',
  ANALYTICS_OPERATIONAL = 'analytics/operational',
  USERS_VIEW = 'users/view',
  USERS_SAVE = 'users/save',
  AUTOMATION_RULE_VIEW = 'automation_rule/view',
  AUTOMATION_RULE_SAVE = 'automation_rule/save',
  AUTOMATION_RULE_DELETE = 'automation_rule/delete',
  AUTOMATION_RULE_ACTIVATE = 'automation_rule/activate',
  DEFAULT_DISTRIBUTION_VIEW = 'default_distribution/view',
  DEFAULT_DISTRIBUTION_SAVE = 'default_distribution/save',
  TEMPLATE_MOVE = 'template/move',
  IMAGE_MOVE = 'image/move',
  EMAIL_VALIDATION_VIEW = 'email_validation/view',
  EMAIL_VALIDATION_SAVE = 'email_validation/save',
  EMAIL_VALIDATION_EXPORT = 'email_validation/export',
  ANALYTICS_INSIGHTS = 'analytics/insights',
  POSTBACK_VIEW = 'postback/view',
  POSTBACK_SAVE = 'postback/save',
  AI = 'analytics/ai',
  LIST_MANAGE = 'list/manage',
}

export type UserPermissions = {
  hasDashboardViewPermission: boolean;
  hasListChangePermission: boolean;
  hasListImportViewPermission: boolean;
  hasListExportViewPermission: boolean;
  hasSegmentsViewPermission: boolean;
  hasNewSegmentsViewPermission: boolean;
  hasEditSegmentViewPermission: boolean;
  hasContactsManagerViewPermission: boolean;
  hasNewContactsSearchViewPermission: boolean;
  hasContactSearchReportViewPermission: boolean;
  hasFormBuilderPermission: boolean;
  hasConversionPointsViewPermission: boolean;
  hasNewConversionPointsViewPermission: boolean;
  hasEditConversionPointViewPermission: boolean;
  hasAutomationRulesViewPermission: boolean;
  hasAutomationRulesViewPermissionWithoutListCheck: boolean;
  hasListSettingsViewPermission: boolean;
  hasAIViewPermission: boolean;
  hasCampaignOverviewViewPermission: boolean;
  hasCampaignsABViewPermission: boolean;
  hasCampaignsCalendarViewPermission: boolean;
  hasCampaignsTransactionalViewPermission: boolean;
  hasEventsAndTriggersViewPermission: boolean;
  hasConnectionsViewPermission: boolean;
  hasSMSVendorsViewPermission: boolean;
  hasESPAndSMTPVendorsViewPermission: boolean;
  hasEditConnectionViewPermission: boolean;
  hasContentEmailsViewPermission: boolean;
  hasContentEmailFormViewPermission: boolean;
  hasContentSMSViewPermission: boolean;
  hasContentImagesViewPermission: boolean;
  hasContentTemplatesViewPermission: boolean;
  hasAnalyticsTransactionalViewPermission: boolean;
  hasAnalyticsOverviewViewPermission: boolean;
  hasAnalyticsAggregateViewPermission: boolean;
  hasAnalyticsEventsViewPermission: boolean;
  hasAnalyticsMatrixViewPermission: boolean;
  hasAnalyticsContactsActivityViewPermission: boolean;
  hasAnalyticsInsightsViewPermission: boolean;
  hasAnalyticsSplitCampaignsViewPermission: boolean;
  hasAnalyticsAggregateReportViewPermission: boolean;
  hasValidationIndexViewPermission: boolean;
  hasValidationFormViewPermission: boolean;
  hasValidationSavePermission: boolean;
  hasAccountSpecsViewPermission: boolean;
  hasDomainRoutingViewPermission: boolean;
  hasAccountSettingsViewPermission: boolean;
  hasAccountConfigurationsPermission: boolean;
  hasImageTrackingViewPermission: boolean;
  hasUserManagementViewPermission: boolean;
  hasListManagementViewPermission: boolean;
  hasNopIndexViewPermission: boolean;
  hasSetupWelcomeViewPermission: boolean;
  hasProfilePermission: boolean;
  hasProfileSettingsViewPermission: boolean;
  hasMarketingAutomationPermission: boolean;
  hasListPostbacksPermission: boolean;
  hasLinksGropusViewPermission: boolean;
  hasChoosePlanPermission: boolean;
};
