import { AccountForms, ConversionPoint, PostBackLink } from '@module/list/store/models';
import { csvFileColumns } from '@utils/csvDownload';
import { DateFieldFormats } from '@utils/DateFieldFormats';

import {
  BehavioralCriterion,
  BehavioralFieldNames,
  BehavioralTypes,
  ListFieldTypes,
  ListSystemCriterion,
  Operator,
  SystemFieldTypes,
} from './Criteria';

export * from './Criteria';

export interface ListId {
  listId: number | 'all';
}

export interface SearchParams {
  ids?: Array<number | string>;
  offset?: number;
  limit?: number;
  order?: 'asc' | 'desc';
  sort?: string;
  search?: string;
}

export interface ExportToCSVParams {
  format: 'json' | 'csv';
  columns: ReturnType<typeof csvFileColumns>;
  csv_title: string;
  time_zone?: string;
  limit: number;
  list_id: string;
}

export type Campaign = {
  id: number | string;
  name: string;
  type: string;
  status: string;
};

export type ListField = {
  id: number | string;
  name: string;
  title: string;
  type: ListFieldTypes;
  mandatory: boolean;
  position: number;
  format: DateFieldFormats;
  default: string | null;
  list_id: number;
};

type CriterionCounts = {
  active: number;
  bounced: number;
  complaint: number;
  unsubscribed: number;
};

export type ModifierFullName = {
  modifier_full_name: string;
};

export enum SegmentEnum {
  REGULAR = 'regular',
  REVERTED = 'reverted',
  SPLIT = 'split',
  SPLIT_CHILD = 'split_child',
  WHITELIST = 'whitelist',
  EXTERNAL = 'external',
  AUDIENCE = 'audience',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  SSL_DATE_EXPIRES_SOON = 'ssl_date_expires_soon',
  SSL_DATE_EXPIRED = 'ssl_date_expired',
}

export const SegmentEnumHumanReadable = {
  [SegmentEnum.REGULAR]: 'Regular',
  [SegmentEnum.REVERTED]: 'Reverted',
  [SegmentEnum.SPLIT]: 'Split',
  [SegmentEnum.WHITELIST]: 'Whitelist',
  [SegmentEnum.EXTERNAL]: 'External',
  [SegmentEnum.AUDIENCE]: 'Audience',
  [SegmentEnum.PROCESSING]: 'Processing',
  [SegmentEnum.COMPLETE]: 'Complete',
  [SegmentEnum.SSL_DATE_EXPIRES_SOON]: 'SSL date expires soon',
  [SegmentEnum.SSL_DATE_EXPIRED]: 'SSL date expired',
};

export type EntriesCsvReportTypes =
  | 'existing'
  | 'not_existing'
  | 'invalid'
  | 'duplicate'
  | 'incomplete';

export type Segment = {
  id: number;
  name: string;
  type: SegmentEnum;
  criteria: ListSystemCriterion;
  description: string;
  favorite: boolean;
  last_count: number;
  last_count_date: number;
  last_count_request_date: number;
  counts: CriterionCounts | null;
  last_sent: number;
  segment_quota: number;
  is_quota_percent: boolean;
  split_parent_id: number | null;
  split_percent: number | null;
  is_split_active: boolean;
  is_split_live: boolean;
  created_by: number;
  modified_by: number;
  user_modified: number;
  expired_date: number | null;
  // TODO What is this?
  included_segment_cache: null;
  excluded_segment_cache: null;
  default_include_segment: boolean;
  default_exclude_segment: boolean;
  audience_position: number;
  default_whitelist_segment: boolean;
  external_url: string | null;
  external_insert_missing: boolean;
  external_include_unsubscribes: boolean;
  external_use_internal_field_values: boolean;
  account_id: number;
  list_id: number;
  created: number;
  modified: number;
  deleted: number | null;
  hours_passed_since_count_request: number;
};

export interface SegmentDeleteImplications {
  can_be_deleted: boolean;
  entities: {
    active_transactional_campaigns: Campaign[];
    completed_campaigns: Campaign[];
    completed_with_errors_campaigns: Campaign[];
    error_campaigns: Campaign[];
    in_progress_campaigns: Campaign[];
    in_progress_pre_processing_campaigns: Campaign[];
    new_campaigns: Campaign[];
    scheduled_campaigns: Campaign[];
    stopped_campaigns: Campaign[];
  };
  has_implications: boolean;
  has_implications_per_entity: {
    active_events: boolean;
    active_transactional_campaigns: boolean;
    completed_campaigns: boolean;
    completed_with_errors_campaigns: boolean;
    error_campaigns: boolean;
    in_progress_campaigns: boolean;
    in_progress_pre_processing_campaigns: boolean;
    list_distribution: boolean;
    new_campaigns: boolean;
    scheduled_campaigns: boolean;
    stopped_campaigns: boolean;
    welcome_email_message_per_segment: boolean;
    welcome_sms_message_per_segment: boolean;
  };
  prevent_delete_per_entity: {
    active_events: boolean;
    active_transactional_campaigns: boolean;
    in_progress_pre_processing_campaigns: boolean;
  };
}

export interface ListFieldDeleteImplications {
  can_be_deleted: boolean;
  entities: {
    account_form_list_field: AccountForms[];
    active_automation_rule_criteria: []; // TODO Add type
    active_automation_rule_update_field_actions: [];
    active_automation_rule_webhook_actions: [];
    active_events: []; // TODO Add type
    conversion_point_list_field: ConversionPoint[];
    message_dynamic_content: []; // TODO Add type
    post_back_list_field: PostBackLink[];
    scheduled_campaigns: Campaign[]; // I'm not sure that this is exactly same type
    segment_criteria: Segment[];
    template_dynamic_content: []; // TODO Add type
  };
  has_implications: boolean;
  has_implications_per_entity: {
    account_form_list_field: boolean;
    active_automation_rule_criteria: boolean;
    active_automation_rule_update_field_actions: boolean;
    active_automation_rule_webhook_actions: boolean;
    active_events: boolean;
    conversion_point_list_field: boolean;
    list_email_field: boolean;
    list_sms_field: boolean;
    message_dynamic_content: boolean;
    post_back_list_field: boolean;
    scheduled_campaigns: boolean;
    segment_criteria: boolean;
    template_dynamic_content: boolean;
  };
  prevent_delete_per_entity: {
    active_automation_rule_criteria: boolean;
    active_automation_rule_update_field_actions: boolean;
    active_automation_rule_webhook_actions: boolean;
    active_events: boolean;
    list_email_field: boolean;
    list_sms_field: boolean;
    post_back_list_field: boolean;
    segment_criteria: boolean;
  };
}

export interface ConversionPointDeleteImplications {
  segments: Record<number, string> | []; // [ id => name ] FAPI namespace Ongage\ListsBundle\Context\ConversionPoint; class DeleteImplications
  campaigns: Record<number, string> | [];
}

export interface Domain {
  account_id: number;
  created: number;
  deleted: number | null;
  id: number;
  modified: number;
  name: string;
}

export interface Implication {
  has_implications_per_entity: Record<string, boolean>;
  entities: Record<
    string,
    { id: string | number; name?: string | undefined; title?: string | undefined }[]
  >;
}

export interface CanDeleteImplication<T extends Implication> {
  entity_name: keyof T['entities'];
  implication_title: string;
}

export interface CanNotDeleteImplication<T extends Implication> {
  entity_name: keyof T['has_implications_per_entity'];
  implication_title: string;
}

export enum ESPConnectionProviderType {
  SMS = 'sms',
  ESP = 'esp',
}

export interface ESPConnection {
  id: number;
  esp_id: number;
  title: string | null;
  esp_name_with_connection_title: string; // virtual field, make sure you add this to request
  image_host_base?: string | null; // select field
  tracking_domain?: string | null; // select field
  esp_name: string;
  provider_type: ESPConnectionProviderType;
  active: boolean;
  created: number;
  modified: number;
}

export interface ESPConnectionData extends ESPConnection {
  custom_headers: string | null;
  deactivated: boolean;
  rating: number;
  cpm_rate: number;
  currency: string;
  contact_title: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_email: string;
  contact_mobile: string;
  contact_phone: string;
  contact_phone_ext: string;
  bounce_domain: string;
  help_list_id: number | null;
  help_message_id: number | null;
  help_transactional_id: number | null;
  unsubscribe_keywords: string | null;
  allow_email_override_name_addressee: boolean;
  bypass_addresses_validation: boolean;
  has_name_addressee: boolean;
  has_from_addressee: boolean;
  has_reply_addressee: boolean;
  progress: number;
  credentials_verified: boolean;
  bounce_domain_verified: boolean;
  setup: boolean;
  setup_date: number;
  contract: number;
  creator_id: number;
  modifier_id: number | null;
  account_id: number;
  deleted: number | null;
  logo: string;
  creator_full_name: string;
  modifier_full_name: string | null;
  use_bounce: boolean;
  is_bounce_domain_optional: boolean;
}

export interface DefaultDistribution {
  esp_connection_id: number;
  isp_id: number | null;
  segment_id: number | null;
  percent: string;
  quota: string;
  default?: boolean;
  list_id?: number | null;
  account_id?: number | null;
}

export interface DomainPerList {
  id: number;
  name: string;
  image_host_base: string | undefined;
  tracking_domain: string | undefined;
  default: boolean;
}

export enum OCX_STATUS_NAME {
  UNSUBSCRIBED = 'unsubscribed',
  ACTIVE = 'active',
  COMPLAINT = 'complaint',
  HARD_BOUNCED = 'hard_bounced',
}

export interface Contact extends Record<string, unknown> {
  id: string;
  email: string;
  ocx_status: string;
  ocx_created_date: number;
  ocx_unsubscribe_date: number;
  ocx_resubscribe_date: number;
  ocx_bounce_date: number;
  ocx_complaint_date: number;
  ocx_import_id: string | number | null;
  ocx_status_name: OCX_STATUS_NAME;
}

export enum ContactStatus {
  ALL = 'all',
  ACTIVE = 'active',
  NON_ACTIVE = 'nonactive',
  UNSUBSCRIBED = 'unsubscribed',
  BOUNCED = 'bounced',
  COMPLAINED = 'complained',
}

export const CONTACT_STATUS_OPTIONS = [
  {
    value: ContactStatus.ALL,
    label: 'All',
  },
  {
    value: ContactStatus.ACTIVE,
    label: 'Active',
  },
  {
    value: ContactStatus.NON_ACTIVE,
    label: 'Non-active',
  },
  {
    value: ContactStatus.UNSUBSCRIBED,
    label: 'Unsubscribed',
  },
  {
    value: ContactStatus.BOUNCED,
    label: 'Hard Bounced',
  },
  {
    value: ContactStatus.COMPLAINED,
    label: 'Complained',
  },
];

export interface DecodedJWT {
  account_id: number;
  account_identifier: string;
  exp: number;
  iat: number;
  identifier: string;
  iss: string;
  role: string;
  type: string;
  user_id: number;
  version: 1;
}

export interface SystemField {
  id: number;
  name: string;
  title: string;
  type: SystemFieldTypes | null;
  possible_values: Array<{ name: string; title: string }> | null;
  allow_multiple: boolean;
  supported_in: string[];
}

export interface BehavioralField {
  name: (typeof BehavioralFieldNames)[keyof typeof BehavioralFieldNames];
  title: string;
  operator: Operator.IS | Operator.IS_NOT;
}

export const isListField = (field: ListField | SystemField | BehavioralField): field is ListField =>
  (field as ListField).format !== undefined;

export const isSystemField = (
  field: ListField | SystemField | BehavioralField,
): field is SystemField => (field as SystemField).possible_values !== undefined;

export const isBehavioralField = (
  field: ListField | SystemField | BehavioralField,
): field is BehavioralField => (field as BehavioralField).operator !== undefined;

export const isBehavioralCriterion = (
  criterion: ListSystemCriterion | BehavioralCriterion,
): criterion is BehavioralCriterion =>
  (criterion as BehavioralCriterion).type === BehavioralTypes.BEHAVIORAL;

export const isListOrSystemField = (
  field: ListField | SystemField | BehavioralField,
): field is ListField | SystemField => isListField(field) || isSystemField(field);

export const isListSystemCriterion = (
  criterion: ListSystemCriterion | BehavioralCriterion,
): criterion is ListSystemCriterion =>
  Object.values(ListFieldTypes).includes(criterion.type as ListFieldTypes) ||
  Object.values(SystemFieldTypes).includes(criterion.type as SystemFieldTypes);

// Account related
export interface ThrottleConfig {
  delivery_timing_type: string;
  throttling: {
    throttling_type: ThrottlingTypes;
    hours: string | number;
    days: string | number;
    start_time: string;
    end_time: string;
    emails?: string | number;
  };
}

export interface CampaingsTimeToSendConfig {
  campaigns_time_to_send_config: ThrottleConfig;
}

// TODO Fill all nullable types when needed
export type AccountModel = {
  id: number;
  name: string;
  code: string;
  timezone: string;
  created: string;
  modified: string;
  frozen: number | null;
  deleted: number | null;
  default_list_id: number;
  default_image_host: string;
  default_tracking_domain: string;
  limits: {
    contacts: {
      inherit_from_package: boolean;
    };
    lists: {
      inherit_from_package: boolean;
    };
    users: {
      inherit_from_package: boolean;
    };
    active_connections_per_vendor: {
      inherit_from_package: boolean;
    };
    vendors_with_active_connections: {
      inherit_from_package: boolean;
    };
    active_events: {
      inherit_from_package: boolean;
    };
    api_access: {
      inherit_from_package: boolean;
    };
  };
  max_list_fields: string;
  notification_email: string;
  contact_email: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_title: string;
  contact_phone: string;
  contact_mobile: string;
  register_to_newsletter_time: number | null;
  implicit_accept_terms_time: number | null;
  website: string;
  address: string;
  logo_file_name: string;
  referrer: null;
  token: string;
  payment_completed: string;
  package_id: string;
  invoice_id: string;
  invoice_status: string;
  plimus_account_id: string;
  sf_lead_id: string;
  sf_account_id: string;
  sf_opportunity_id: string;
  epom_username: null;
  epom_password: null;
  allow_audience_analytics: string;
  bypass_html_purifier: string;
  auto_beautify_html: string;
  bypass_message_blacklist: string;
  bypass_addressee_blacklist: string;
  bypass_unsubscribe_link: string;
  unsubscribe_default_link: string;
  bypass_quota_notifications: string;
  bypass_contact_search_from_to_validation: string;
  can_delete_export: string;
  exclude_test_campaign_dashboards: string;
  exclude_test_campaign_analytics: string;
  prepand_test_to_test_emails: string;
  collect_platform_stats: string;
  hide_platform_stats: string;
  collect_country_stats: string;
  hide_country_stats: string;
  collect_browser_stats: string;
  hide_browser_stats: string;
  do_not_collect_contact_ip: string;
  content_text_no_link_tracking: string;
  preprocess_minutes: string;
  campaigns_time_to_send_config: ThrottleConfig | null;
  trial_expiration_date: string;
  is_setup_esp_connection: string;
  is_setup_tracking_domain: string;
  is_setup_wizard_completed: string;
  last_salesforce_sync: string;
  creation_ip: string;
  max_stats_years: string;
  support_email_recipients: string;
  custom_support_message: string;
  registration_lead_info: null;
  is_won: string;
  is_qa: string;
  is_auth_2fa: string;
  require_email_verification: string;
  registration_query_params: null;
  allow_list_providers_restriction: string;
  imports_password: string;
  export_password: null;
  imports_concurrent_limit: string;
  external_segment_password: string;
  customer_contacts_activity_webhook_url: string;
  max_users: string | number | null;
  users_count: string;
  allow_timezone_per_user: string;
  active_events_count: string;
  active_events_limit: string | number | null;
  triggers_limit: null;
  allow_events_recurrence_monthly: string;
  lists_count: string;
  lists_limit: string | number | null;
  max_suppression_lists_per_sending: string;
  email_volume: string;
  conversion_points_count: string;
  conversion_points_limit: null;
  split_winner_feature: string;
  split_campaigns_test_limit: string;
  ongagemark_id: null;
  onboarding_indications: string;
  allowed_api_calls_per_minute: string;
  allowed_api_calls_per_minute_per_method: null;
  apply_default_settings_in_api: string;
  is_stats_paused: string;
  max_import_size: string;
  analytics_exports_date_format: string;
  sf_owner: string;
  sf_vertical: null;
  sf_date_changed_to_paying_client: null;
  sf_contract_end_date: null;
  sf_pack_value: string;
  audience_relevant: string;
  allow_realtime_events: string;
  newrelic_browser_active: string;
  max_segments_per_campaign: string;
  active_segments_per_list_limit: string;
  max_behavioral_segment_campaigns: string;
  included_segment_cache: number;
  excluded_segment_cache: number;
  segment_cache: string;
  allow_ocx_import_id_all: string;
  allow_ocx_if_else_in_content_text: string;
  enable_email_validation_activity_level: string;
  enable_email_validation_activity_level_extended_options: string;
  enable_email_validation_activity_date: string;
  enable_sto: string;
  max_whitelist_segment_size: string;
  long_string_fields_limit_per_list: string;
  huge_string_fields_limit_per_list: string;
  max_campaigns_in_search: string;
  max_allowed_url_params: string;
  esps_with_active_connections_count: string;
  esps_with_active_connections_limit: string | number | null;
  active_esp_connections_per_vendor_limit: string | number | null;
  whitelabel_connections: null;
  mail_tester_daily_limit: string;
  hide_litmus_preview: string;
  show_ox_data: string;
  ox_behavior_fields_order: null;
  sent_current_month: string;
  sent_current_month_count_date: string;
  allow_email_plaintext: string;
  images_count: string;
  images_limit: null;
  hide_root_folders: string;
  contacts_count: string;
  contacts_limit: string | number | null;
  email_sms_monthly_limit: string;
  regular_split_campaigns_monthly_limit: string;
  allow_campaign_count: string;
  allow_throttling_dayparting_distribution: string;
  allow_ip_warmup: string;
  settings: Partial<{
    ssl: {
      enable: boolean;
    };
    auth_v2: {
      enable_sync: boolean;
    };
    split_sto: {
      enable: boolean;
    };
    analytics_ai: {
      enable: boolean;
    };
    analytics_v2: {
      enable: boolean;
      enable_api: boolean;
    };
    user_guiding: {
      enable: boolean;
    };
    import_revert: {
      enabled: boolean;
    };
    dedicated_host: {
      url: string;
      enable: boolean;
    };
    analytics_cache: {
      enable: boolean;
    };
    domain_verification: {
      allow_scheduled_campaigns_with_unverified_domain: boolean;
    };
    marketing_automation: {
      enable: boolean;
    };
    prevent_creating_connections: {
      enable: boolean;
    };
    report_not_sent_due_to_empty_content: {
      enable: boolean;
    };
    bot_detection: {
      enable_analytics: boolean;
      enable_unsubscribe_confirmation: boolean;
    };
  }>;
  journeys_count: string;
  journeys_limit: null;
  api_access: string;
  use_smart_filters_in_analytics: string;
  need_stats_recount: string;
  enable_email_masking: string;
  events_esp_segment_distribution: string;
  cke_keep_spaces_empty_blocks: string;
  allow_distribution_less_100_percent: string;
  allow_sync_contact_activity: string;
  allow_avg_time_in_contact_activity: string;
  contact_list_id_validation_verbose: string;
  unique_click_per_mailing: string;
  disable_tracking: string;
  disable_opening: string;
  allowed_ips_enable: string;
  allowed_ips: string[];
  link_query_params: string;
  automation_rules: {
    limits: {
      active_automation_rules_per_list: number;
      active_actions_per_automation_rule: number;
    };
    enabled: boolean;
    on_trial: boolean;
    show_in_ui: boolean;
    trial_end_date: number;
    trial_start_date: number;
    allow_creating_ar: boolean;
    interested_in_trial_date: number;
  };
  ignore_apple_proxy_opens_in_ar: boolean;
  ignore_apple_proxy_opens_in_events: boolean;
  ignore_apple_proxy_opens_in_segments: boolean;
  disable_implied_opens: string;
  vertical: string;
  restricted_to_provider_ids: null;
  is_demo_account: boolean;
  // account specifications table
  messages_count?: number;
  api_calls_count?: number;
  mail_tester_count?: number;
  campaigns_count?: number;
  // analytics bot detection
  preferences: {
    bot_detection: {
      analytics_default: boolean;
    };
  } | null;
};

export enum InvoiceStatuses {
  APPROVED = 'approved',
  REFUND_REQUESTED = 'refund_requested',
  DECLINED = 'declined',
}

export type AccountInvoiceDetails = {
  status: InvoiceStatuses;
  can: {
    upgrade: boolean;
    signup: boolean;
  };
};

export type RegularUserUpdateAccountPayload = {
  // Defaults
  default_list_id: number;
  default_image_host: string;
  default_tracking_domain: string;
  // Info
  contact_email: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_title: string;
  contact_phone: string;
  contact_mobile: string;
  website: string;
  address: string;
  logo_file_name: string;
  notification_email: string;
  // Flags
  exclude_test_campaign_dashboards: boolean;
  exclude_test_campaign_analytics: boolean;
  prepand_test_to_test_emails: boolean;
  is_stats_paused: boolean;
  split_winner_feature: boolean;
  enable_email_masking: boolean;
  cke_keep_spaces_empty_blocks: boolean;
  events_esp_segment_distribution: boolean;
  auto_beautify_html: boolean;
  is_auth_2fa: boolean;
  // Settings
  analytics_exports_date_format: string;
  enable_segment_cache: boolean;
  included_segment_cache: number | null;
  excluded_segment_cache: number | null;
  imports_password: string;
  external_segment_password: string;
  unsubscribe_default_link: boolean;
  enable_throttling: boolean;
  allowed_ips: Array<string>;
  allowed_ips_enable: boolean;
  customer_contacts_activity_webhook_url: string;
  link_query_params: { [key: string]: string };
  timezone: string;
  ignore_apple_proxy_opens_in_ar: boolean;
  ignore_apple_proxy_opens_in_events: boolean;
  ignore_apple_proxy_opens_in_segments: boolean;
  campaigns_time_to_send_config: ThrottleConfig | null;
  // Account setup
  is_setup_wizard_completed: boolean;
  // Whitelabel connection
  whitelabel_connections: string; // TODO in php model type is JSON
  bot_detection_analytics_default: boolean;
};

export type QueryParamsToSend = {
  [key: string]: string;
};

export interface DeactSettingsPesponseData {
  result: {
    unsubscribe: string;
    bounce: string;
    complaint: string;
  };
}

export interface AccountPkgModel {
  id: number;
  title: string;
  sf_title: string;
  price: number;
  email_volume: number;
  bluesnap_contract_id: number;
  is_custom_package: boolean;
  is_full_solution: boolean;
  is_expired: boolean;
  max_users: number;
  lists_limit: number;
  max_suppression_lists_per_sending: number;
  active_events_limit: number | null; //?
  triggers_limit: number;
  esps_with_active_connections_limit: number;
  active_esp_connections_per_vendor_limit: number;
  conversion_points_limit: number;
  images_limit: number;
  contacts_limit: number;
  journeys_limit: number;
  max_list_fields: number;
  split_winner_feature: number;
  api_access: boolean;
  created: number;
  modified: number;
  deleted: number;
  // account specifications table
  messages_limit: number;
  api_calls_limit: number;
  mail_tester_limit: number;
  campaigns_limit: number;
}

export type LinkQueryParams = {
  link_query_params: Array<{ name: string; value: string }>;
};

export enum ThrottlingTypes {
  HOURS = 'hours',
  DAYPARTING = 'dayparting',
  EMAILS_PER_DAY = 'emails_per_day',
}

export interface UserFormFields {
  username: string;
  user_role_id?: string;
  role_title?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  mobile: string;
  reset_2fa: boolean;
  user_allowed_sending_list_ids: number[] | null | undefined;
  auth_user_password?: string;
  password?: string;
  re_password?: string;
  is_admin?: boolean;
  is_admin_manager?: boolean;
  is_super_admin?: boolean;
  verified_by_email?: boolean;
  timezone?: string;
}

export interface EditUserFormFields extends UserFormFields {
  id: number;
  auth_2fa_hide_qr_code?: boolean;
  reset_2fa_with_code?: boolean;
  twoFa_code?: string;
  all_lists_are_allowed: boolean;
}

export type ArchiveListParams = {
  listIdToArchive: number;
  archive: boolean;
};

export type Vendor = {
  id: number;
  type: string;
  sub_type: string;
  name: string;
  description: string;
  logo: string;
  website: string;
  delivery_region: string;
  offices: string;
  setup_fee: string;
  free_trial: string;
  references: string;
  address: string;
  contact_title: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_email: string;
  contact_phone: string;
  contact_phone_ext: string;
  contact_mobile: string;
  contact_url: string;
  addresses_instructions: string;
  credentials_instructions: string;
  special_instructions: string;
  instructions_login_required: boolean;
  create_account_url: string;
  login_url: string;
  wiki_page_url: string;
  unique_issues: string;
  bounce_hostcredentials_instructions: string;
  use_bounce: boolean;
  use_soft_bounce: boolean;
  bounce_domain_user: string;
  is_bounce_domain_optional: boolean;
  soft_bounce_received: boolean;
  sf_sent_by_vendor_field_name: string;
  mapping_status: number;
  dynamic_fields_limit: number;
  allow_dynamic_fields_in_subject: boolean;
  max_field_name_length: number | null;
  has_account: boolean;
  can_deactivate_accounts: boolean;
  require_manual_setup: boolean;
  is_virtual_smtp: boolean;
  api_as_gui: boolean;
  from_address_required: boolean;
  reply_address_required: boolean;
  enforce_unsubscribe_link: boolean;
  hide_connection_option: boolean;
  overtakes_iframe: boolean;
  multilist_support_type: number;
  can_be_verified: boolean;
  comments: string;
  hide: boolean;
  is_custom: boolean;
  is_whitelabel: boolean;
  send_email_on_activation: boolean;
  send_email_on_activation_message: string;
  support_plaintext: boolean;
  support_transactional: boolean;
  support_unsubscribe_keywords: boolean;
  support_help_message: boolean;
  require_ip: boolean;
  require_addresses_match: boolean;
  created: number;
  modified: number;
  deleted: number | null;
};

// user roles used in decoded token
export enum TokenUserRoles {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
  GENERAL_USER = 'general_user',
  REPORT_VIEWER = 'analytics_agent',
  EMAIL_MARKETER = 'email_marketer', // (No Import/Export) is by default
  EMAIL_MARKETER_NO_LIST_SETTINGS = 'email_marketer_no_list_settings',
  CONTENT_WRITER = 'content_writer',
  GENERAL_USER_NO_EXPORT = 'no_export',
}
