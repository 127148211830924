import { FC } from 'react';
import { Button } from '@ui/Button';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import styles from './OutdatedCache.module.scss';

export const OutdatedCache: FC = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.section}>
      <Image src="/media/svg/general/outdated-cache.svg" alt="Outdated cache error image." />
      <h1>Outdated Cache Detected</h1>
      <p>
        It seems the page’s data is outdated. Please refresh
        <br /> the page to continue.
      </p>
      <Button variant="info" onClick={() => navigate(0)}>
        Refresh Page
      </Button>
    </section>
  );
};
