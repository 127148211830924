import { FC, forwardRef, useCallback, useRef, useState } from 'react';
import { FapiResponse } from '@module/shared/models/FapiResponse';
import { useMutation } from '@tanstack/react-query';
import { Icon } from '@ui/Icon/Icon';
import { toastError } from '@ui/Toasts';
import { csvFileColumns } from '@utils/csvDownload';
import { AxiosError } from 'axios';
import { Col, Dropdown, DropdownToggleProps } from 'react-bootstrap';

import styles from '../../../../ui/table/controls/ControlWrapper.module.scss';
import { useAccountInfo } from '../../hooks/account-queries';
import { useTableFilters } from '../../hooks/useTableFilters';
import { useTableDataContext } from '../../store/useTableColumns';
import { useSelectedListId } from '@module/list/store/useListStore';

type Props = (WithExport | WithoutExport) & (WithPagination | WithoutPagination);

type WithPagination = {
  useItemsPerPage: true;
  itemsPerPage?: number[];
};

type WithoutPagination = {
  useItemsPerPage?: false;
  itemsPerPage?: never;
};

export type ExportQueryParams = {
  format: 'json' | 'csv';
  columns: ReturnType<typeof csvFileColumns>;
  csv_title: string;
  time_zone?: string;
  limit: number;
};

type ExportQueryType = ReturnType<
  typeof useMutation<FapiResponse<{ url: string }>, Error, unknown>
>;

export type ExportLinkBuilder = (
  listId: number | 'all',
  exportQueryParams: ExportQueryParams,
) => string;

type WithExportBase = {
  name: string;
  type: 'link' | 's3' | 'no-export';
};

type WithExport = WithExportBase &
  (
    | { type: 's3'; exportQuery: ExportQueryType }
    | { type: 'link'; exportLinkBuilder: ExportLinkBuilder }
  );

type WithoutExport = {
  name?: never;
  type: 'no-export';
  exportQuery?: never;
  exportLinkBuilder?: never;
};

const defaultItemsPerPage = [10, 25, 50, 100];

export const CommonTableActions: FC<Props> = ({
  useItemsPerPage = true,
  itemsPerPage,
  ...props
}) => {
  let exportLinkBuilder: ExportLinkBuilder | undefined = undefined;
  let exportQuery: ExportQueryType | undefined = undefined;

  if (props.type === 'link') {
    exportLinkBuilder = props.exportLinkBuilder;
  }

  if (props.type === 's3') {
    exportQuery = props.exportQuery;
  }

  const memoItemsPerPage = useRef(useItemsPerPage ? itemsPerPage || defaultItemsPerPage : []);

  const [showItemsPerPage, setShowItemsPerPage] = useState(false);

  return (
    <Dropdown drop="down" autoClose="outside">
      <Dropdown.Toggle as="div" className={styles.controlWrapper}>
        <Icon name="dotsVertical" width={22} height={22} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border w-275px mt-4">
        {/* CSV Export */}
        {!!props.type && (
          <DownloadCsvDropdown
            name={props.name || ''}
            exportQuery={exportQuery}
            exportLinkBuilder={exportLinkBuilder}
          />
        )}

        {/* Items Per Page */}
        {useItemsPerPage && (
          <Dropdown.Item
            className="d-flex flex-row align-items-center"
            as="span"
            onClick={() => setShowItemsPerPage((s) => !s)}
          >
            <Col className="d-flex flex-row gap-2 px-2 py-1">
              <Icon name="arrowDown" />
              <span className="text-gray-700 fs-6">Items per page</span>
            </Col>
            <Col className="d-flex flex-row gap-2 px-2 py-1 justify-content-end cursor-pointer">
              <ItemsPerPageDropdown
                itemsPerPage={memoItemsPerPage.current}
                show={showItemsPerPage}
              />
            </Col>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const EXPORT_LIMIT = 40_000;
const DownloadCsvDropdown: FC<{
  name: string;
  exportQuery?: ExportQueryType;
  exportLinkBuilder?: ExportLinkBuilder;
}> = ({ name, exportQuery, exportLinkBuilder }) => {
  if (exportQuery) {
    return <ExportQueryCsv exportQuery={exportQuery} />;
  }

  if (exportLinkBuilder) {
    return <ExportLinkCsv name={name} exportLinkBuilder={exportLinkBuilder} />;
  }

  return null;
};

const ExportQueryCsv: FC<{ exportQuery: ExportQueryType }> = ({ exportQuery }) => {
  const { mutate: exportToCsv, isPending } = exportQuery;

  const handleCsvExport = useCallback(() => {
    exportToCsv(
      {},
      {
        onSuccess: (resp) => {
          window.open(resp.payload.url, '_blank');
        },
        onError: (error) =>
          toastError({
            title: 'Export CSV',
            text:
              error instanceof AxiosError
                ? error.response?.data?.payload?.message
                : 'Something went wrong. Please try again.',
          }),
      },
    );
  }, [exportToCsv]);

  return (
    <Dropdown.Item
      as="span"
      className="cursor-pointer"
      onClick={handleCsvExport}
      disabled={isPending}
    >
      <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1">
        <Icon name="downloadCloud" />
        <span className="text-gray-700 fs-6">CSV</span>
      </Col>
    </Dropdown.Item>
  );
};

const ExportLinkCsv: FC<{ name: string; exportLinkBuilder: ExportLinkBuilder }> = ({
  name,
  exportLinkBuilder,
}) => {
  const listId = useSelectedListId();

  const { data: account } = useAccountInfo();
  const columns = useTableDataContext((state) => state.columns);

  const link = exportLinkBuilder(listId!, {
    format: 'csv',
    columns: csvFileColumns(columns),
    csv_title: name,
    time_zone: account?.timezone || 'Asia/Jerusalem',
    limit: EXPORT_LIMIT,
  });

  return (
    <Dropdown.Item href={link} target="_blank" rel="norefferer">
      <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1">
        <Icon name="downloadCloud" />
        <span className="text-gray-700 fs-6">CSV</span>
      </Col>
    </Dropdown.Item>
  );
};

const ItemsPerPageDropdown: FC<{ itemsPerPage: number[]; show: boolean }> = ({
  itemsPerPage,
  show,
}) => {
  const { filters, updateFilter } = useTableFilters();

  const handleLimitChange = (limit: number) => {
    updateFilter([{ key: 'limit', value: limit }]);
  };

  return (
    <Dropdown drop="down-centered" show={show}>
      <Dropdown.Toggle as={ItemsPerPageTitle} />
      <Dropdown.Menu className="border w-200px mt-4">
        {itemsPerPage.map((limit) => (
          <Dropdown.Item key={limit} onClick={() => handleLimitChange(limit)}>
            <Col className="d-flex flex-row align-items-center gap-2 px-2 py-1 justify-content-between">
              <span className="text-gray-900 fw-semibold">{limit} items</span>
              {filters.limit === limit && <Icon name="check" stroke="primary" />}
            </Col>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const ItemsPerPageTitle = forwardRef<HTMLSpanElement, DropdownToggleProps>(({ ...rest }, ref) => {
  const { filters } = useTableFilters();
  return (
    <span ref={ref} {...rest} className="text-gray-500 fs-6 justify-content-end">
      {filters.limit} items <Icon name="chevronDown" width={16} height={16} />
    </span>
  );
});
