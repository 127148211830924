import { useMemo } from 'react';
import { EditListFormFields } from '@module/settings/components/list-management/components/EditListForm';
import { NewListFormFields } from '@module/settings/components/list-management/components/NewListForm';
import {
  archiveList,
  checkMultilistSupport,
  copyList,
  downloadImportTemplateCsv,
  editList,
  getListsV2,
  getListsWithFilters,
  getSidebarLists,
  ListFilters,
  newList,
  removeList,
  setNewFrequencyField,
  setNewSmsField,
  updateList,
  updateListProviderConns,
} from '@module/shared/api/list';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { ArchiveListParams, SearchParams } from '@module/shared/models/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { List } from '../store/models';
import { useSelectedListId } from '../store/useListStore';

export const listsKeys = {
  list: () =>
    [
      {
        entity: 'lists',
      },
    ] as const,
  listsWithFilters: (
    listId: number | 'all' | undefined,
    filters?: ListFilters,
    searchParams?: SearchParams,
  ) =>
    [
      {
        listId,
        filters,
        searchParams,
        entity: 'lists',
      },
    ] as const,
  listsV2: (listId: number | 'all' | undefined, searchParams?: ListFilters & SearchParams) =>
    [
      {
        listId,
        searchParams,
        entity: 'listsV2',
      },
    ] as const,
  importTemplateSample: () => [
    {
      entity: 'importTemplate',
    },
  ],
  multilistSupport: () => [
    {
      entity: 'multilistSupport',
    },
  ],
};

export const useSidebarLists = () => {
  return useQuery({
    queryKey: listsKeys.list(),
    queryFn: getSidebarLists,
    select: (data) => data?.payload,
  });
};

export const useListsV2 = (searchParams?: ListFilters & SearchParams) => {
  const listId = useSelectedListId();

  return useQuery({
    queryKey: listsKeys.listsV2(listId, searchParams),
    queryFn: ({ queryKey: [{ listId, searchParams }] }) => getListsV2(listId!, searchParams),
    enabled: !!listId,
  });
};

export const useListsStats = () => {
  const { data: lists, isLoading } = useSidebarLists();

  const stats = useMemo(() => {
    const initialState = {
      count: 0,
      activeCount: 0,
      complaintsCount: 0,
      bouncesCount: 0,
      unsubscribesCount: 0,
    };

    return (
      lists?.reduce((acc, list) => {
        return {
          count: acc.count + list.last_count,
          activeCount: acc.activeCount + list.last_active_count,
          complaintsCount: acc.complaintsCount + list.complaints,
          bouncesCount: acc.bouncesCount + list.bounces,
          unsubscribesCount: acc.unsubscribesCount + list.unsubscribes,
        };
      }, initialState) ?? initialState
    );
  }, [lists]);

  return { stats, isLoading };
};

export const useNewSmsField = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (field_id: number) => setNewSmsField(listId!, field_id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useNewFrequencyField = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (field_id: number) => setNewFrequencyField(listId!, field_id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useListsWithFilters = (filters: ListFilters, forceAllLists = false) => {
  const listId = useSelectedListId();

  const { filters: searchParams } = useTableFilters();

  return useQuery({
    queryKey: listsKeys.listsWithFilters(forceAllLists ? 'all' : listId, filters, searchParams),
    queryFn: ({ queryKey: [{ listId, filters, searchParams }] }) =>
      getListsWithFilters(listId!, { ...filters, ...searchParams }),
    enabled: !!listId,
  });
};

export const useListUpdate = () => {
  const queryClient = useQueryClient();
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (updatedList: Partial<List> & { id: List['id'] }) =>
      updateList(listId!, updatedList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useNewList = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (list: NewListFormFields) => newList({ listId: listId!, ...list }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useRemoveList = () => {
  const listId = useSelectedListId();
  const { filters: searchParams } = useTableFilters();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (listIdToRemove: number) => removeList({ listId: listId!, listIdToRemove }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
        queryClient.invalidateQueries({ queryKey: listsKeys.list() });
    },
  });
};

export const useEditList = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: (list: Omit<EditListFormFields, 'suppress_in_lists'>) =>
      editList({ listId: listId!, ...list }),
  });
};

export const useCopyList = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (list: Partial<List>) => copyList({ listId: listId!, ...list }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: listsKeys.list() }),
  });
};

export const useArchiveList = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();
  const { filters: searchParams } = useTableFilters();

  return useMutation({
    mutationFn: ({ listIdToArchive, archive }: ArchiveListParams) =>
      archiveList({ listId: listId!, listIdToArchive, archive }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: listsKeys.listsV2(listId, searchParams) }),
  });
};

export const useDownloadImportTemplate = () => {
  const listId = useSelectedListId();
  return useMutation({
    mutationFn: () => downloadImportTemplateCsv(listId!),
  });
};

export const useUpdateListProviderConns = () => {
  const listId = useSelectedListId();

  return useMutation({
    mutationFn: ({
      createdListId,
      providerIds,
    }: {
      createdListId: number;
      providerIds: number[] | null;
    }) => updateListProviderConns(listId!, createdListId, providerIds),
  });
};

export const useMultilistSupport = () => {
  return useQuery({
    queryKey: listsKeys.multilistSupport(),
    queryFn: () => checkMultilistSupport(),
  });
};
