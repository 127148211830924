import { FC, ReactNode } from 'react';

import styles from './FiltersContainer.module.scss';

type Props = {
  children: ReactNode;
};

export const FiltersContainer: FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
