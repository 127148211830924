import { MetronicProviders } from '@providers/MetronicProviders';
import { QueryClientProvider } from '@providers/QueryClientProvider';
import { router } from '@routing/AppRoutes';
import { FC } from 'react';

import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { RouterProvider } from 'react-router';

const isDevMode = process.env.NODE_ENV === 'development';

export const App: FC = () => {
  return (
    <QueryClientProvider withDevTools={isDevMode}>
      <I18nProvider>
        <MetronicProviders>
          <RouterProvider router={router} />
        </MetronicProviders>
      </I18nProvider>
    </QueryClientProvider>
  );
};
