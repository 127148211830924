import { create } from 'zustand';

interface MfaTokenState {
  token: string | null;
  qr: string | null;
  secret: string | null;
  actions: {
    setMfaToken: (token: string) => void;
    setMfaSecret: (secret: string) => void;
    setQrCode: (base64: string) => void;
    resetMfa: () => void;
  };
}

export const useMfaTokenStore = create<MfaTokenState>((set) => ({
  token: null,
  qr: null,
  secret: null,
  actions: {
    setMfaToken: (token: string) => set({ token }),
    setMfaSecret: (secret: string) => set({ secret }),
    setQrCode: (qr: string) => set({ qr }),
    resetMfa: () => set({ token: null, qr: null }),
  },
}));

export const useMfaToken = () => useMfaTokenStore((state) => state.token);
export const useMfaSecret = () => useMfaTokenStore((state) => state.secret);
export const useQrCode = () => useMfaTokenStore((state) => state.qr);
export const useMfaTokenActions = () => useMfaTokenStore((state) => state.actions);
