import React, { createContext, FC, PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: ReactElement
  setPageDescription: (_description: ReactElement) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  rightExtraItems?: Array<ReactElement>
  setRightExtraItems: (_ReactElements: Array<ReactElement>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: ReactElement) => {},
  setRightExtraItems: (_reactElements: Array<ReactElement>) => {},
});

const PageDataProvider: FC<PropsWithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<ReactElement>(<></>);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [rightExtraItems, setRightExtraItems] = useState<Array<ReactElement>>([]);

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    rightExtraItems,
    setRightExtraItems,
  };
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: ReactElement
  breadcrumbs?: Array<PageLink>
  rightExtraItems?: ReactElement[]
  tooltip?: ReactElement
}

const PageTitle: FC<PropsWithChildren<Props>> = ({children, description, breadcrumbs, rightExtraItems}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setRightExtraItems} = usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => {
      setPageTitle('');
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription(<></>);
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  useEffect(() => {
    if (rightExtraItems) {
      setRightExtraItems(rightExtraItems);
    }
    return () => {
      setRightExtraItems([]);
    };
  }, [rightExtraItems]);

  return <></>;
};

export {PageTitle, PageDataProvider, usePageData};
