import clsx from 'clsx';
import { FC } from 'react';

const backgroundStyles = {
  width: '100%',
  minHeight: '100vh',
  background: 'url("/media/logos/default.svg") no-repeat center center',
};
const LayoutSplashScreen: FC<{visible?: boolean}> = ({visible = true}) => {
  return <div style={backgroundStyles} className={clsx({ ['d-none']: !visible })} />;
};

export { LayoutSplashScreen };
