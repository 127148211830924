import { FC, PropsWithChildren } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

import '../../_metronic/assets/sass/style.scss';
import '../../_metronic/assets/sass/plugins.scss';
import '../../_metronic/assets/sass/style.react.scss';

export const MetronicProviders: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return <SkeletonTheme>{children}</SkeletonTheme>;
};
