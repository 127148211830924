import { DomainSetup } from '@module/list/store/models';
import { ExportQueryParams } from '@module/shared/ui/table/CommonTableActions';
import axios from 'axios';

import config from '../../../../app-config';
import { FapiResponse } from '../models/FapiResponse';
import { ListId, SearchParams } from '../models/models';
import { buildUrl } from '@utils/url';

const API_URL = config.fapiUrl;
const STATIC_URL = config.ongageApiUrl;

const GET_DOMAINS_URL = (listId: number | 'all') => `${API_URL}/${listId}/api/domain`;
const GET_EXPORT_URL = (listId: number | 'all') => `${STATIC_URL}/${listId}/api/domain`;

export enum DomainType {
  TRACKING = 'tracking',
  IMAGE = 'image',
}
export const HumanReadableDomainType = {
  [DomainType.TRACKING]: 'Tracking',
  [DomainType.IMAGE]: 'Image',
};
export type DomainFilters = {
  // Possible filters
  account_id?: number;
  type?: DomainType;
  domain?: string;
  select?: string[];
  // Boolean filters
  is_https?: boolean;
  verified?: 'true' | 'false';
  deleted?: boolean;
  is_default?: 'true' | 'false';
  // Array filters
  types?: string[];
  https_status_code?: number[];
};
export const getDomainsSetupList = async (
  list_id: number | 'all',
  filters?: DomainFilters & SearchParams,
) =>
  axios
    .get<FapiResponse<DomainSetup[]>>(GET_DOMAINS_URL(list_id), {
      params: {
        offset: filters?.offset,
        limit: filters?.limit || 100,
        sort: filters?.sort,
        order: filters?.order,
        free_search: filters?.search || undefined,
        virtual_fields: ['is_default', 'expiration_date', 'dns_instructions'],
        id: filters?.ids,
        types: filters?.types,
        type: filters?.type,
        https_status_code: filters?.https_status_code,
        is_default: filters?.is_default === 'true' ? true : undefined,
        verified:
          // Filters not selected or both selected
          !filters?.verified ||
          filters.verified.length === 0 ||
          (filters?.verified?.includes('true') && filters?.verified?.includes('false'))
            ? undefined // remove filter to show any domain
            : !!filters?.verified?.includes('true'), // show only favorite domain or not
      },
    })
    .then(({ data }) => data);

export const DOMAINS_LIST_EXPORT = (filters: DomainFilters) => {
  return (list_id: number | 'all', exportParams: ExportQueryParams) => {
    const params = {
      limit: exportParams.limit,
      format: exportParams?.format,
      csv_title: exportParams?.csv_title,
      columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
      list_id,
      time_zone: exportParams?.time_zone,
      virtual_fields: ['is_default', 'expiration_date', 'dns_instructions'],
      types: filters?.types,
      https_status_code: filters?.https_status_code,
      is_default: filters?.is_default === 'true' ? true : undefined,
      verified:
        // Filters not selected or both selected
        !filters?.verified ||
        filters.verified.length === 0 ||
        (filters?.verified?.includes('true') && filters?.verified?.includes('false'))
          ? undefined // remove filter to show any domain
          : !!filters?.verified?.includes('true'), // show only favorite domain or not
    };

    return buildUrl(GET_EXPORT_URL(list_id), params).toString();
  };
};

type VerifyDomainResponse = {
  verified: boolean;
  cname_used: null;
  expected_cname: string;
  domain_data: DomainSetup;
};
export const verifyDomain = async (listId: number | 'all', domain_id: number) =>
  axios
    .put<FapiResponse<VerifyDomainResponse>>(`${GET_DOMAINS_URL(listId)}/${domain_id}/verify`)
    .then(({ data }) => data);

export interface CreatedDomain extends DomainSetup {
  domain_verification_payload: {
    verified: boolean;
    cname_used: string | null;
    expected_cname: string;
  };
}

export const addDomain = async ({
  listId,
  domain,
  type,
  isHttps,
}: ListId & Partial<DomainSetup> & { isHttps?: boolean }) => {
  return await axios
    .post<FapiResponse<CreatedDomain>>(GET_DOMAINS_URL(listId), {
      domain,
      type,
      ['domain-edit-form-is-https']: isHttps,
    })
    .then(({ data }) => data);
};

export const updateDomain = async ({ listId, ...domain }: ListId & Partial<DomainSetup>) => {
  return await axios
    .put<FapiResponse<DomainSetup>>(`${GET_DOMAINS_URL(listId)}/${domain.id}`, domain)
    .then(({ data }) => data);
};

type EntitiesWithReplacedDomain = {
  // TODO Fill unknown types
  email_ids_with_replaced_domain: unknown[];
  template_ids_with_replaced_domain: unknown[];
  list_ids_with_replaced_domain: unknown[];
};
export const deleteDomain = async ({
  listId,
  domainId,
}: ListId & { domainId: DomainSetup['id'] }) => {
  return await axios
    .delete<
      FapiResponse<DomainSetup & { entities_with_replaced_domain: EntitiesWithReplacedDomain }>
    >(`${GET_DOMAINS_URL(listId)}/${domainId}`)
    .then(({ data }) => data);
};
