import { FC, PropsWithChildren } from 'react';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    staleTime: 10_000, // 10 sec is default stale time
    retry: (failureCount, error) => {
      if (
        error &&
        error instanceof AxiosError &&
        error.response?.status &&
        error.response?.status <= 500
      ) {
        return false;
      }
      return failureCount <= 3;
    },
  },
  mutations: {
    retry: (failureCount, error) => {
      if (
        error &&
        error instanceof AxiosError &&
        error.response?.status &&
        error.response?.status <= 500
      ) {
        return false;
      }
      return failureCount <= 3;
    },
  },
});

export type QueryClientProviderProps = PropsWithChildren<{
  withDevTools?: boolean;
}>;

export const QueryClientProvider: FC<QueryClientProviderProps> = ({
  children,
  withDevTools = true,
}) => {
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      {withDevTools && <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />}
    </ReactQueryClientProvider>
  );
};
