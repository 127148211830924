import { List } from '@module/list/store/models';
import { TableSortParams } from '@module/settings/components/list-management/components/ExportImportSupListModal';
import { NewListFormFields } from '@module/settings/components/list-management/components/NewListForm';
import {
  ListHashTypes,
  ListShowArchivedType,
  ListShowType,
} from '@module/settings/components/list-management/filters/ListTableFilters';
import { ExportQueryParams } from '@module/shared/ui/table/CommonTableActions';
import axios from 'axios';

import config from '../../../../app-config';
import { FapiResponse } from '../models/FapiResponse';
import { ArchiveListParams, ListId, SearchParams } from '../models/models';
import { buildUrl } from '@utils/url';

const API_URL = config.fapiUrl;
const STATIC_FILES_URL = config.ongageApiUrl;

const GET_LISTS_URL = `${API_URL}/all/api/lists`;
const GET_NEW_LISTS_URL = (list_id: number | 'all') => `${API_URL}/${list_id}/api/lists`;
const GET_LISTS_URL_V2 = (list_id: number | 'all') => `${API_URL}/${list_id}/api/v2/lists`;
const POST_NEW_LIST_URL = (listId: number | 'all') => `${API_URL}/${listId}/api/lists`;
const REMOVE_LIST_URL = (listId: number | 'all', listIdToRemove: number) =>
  `${API_URL}/${listId}/api/lists/${listIdToRemove}`;
const EDIT_LIST_URL = (listId: number | 'all', listIdToEdit?: number) =>
  `${API_URL}/${listId}/api/lists/${listIdToEdit}`;
const EXPORT_LISTS_CSV_URL = (listId: number | 'all') =>
  `${STATIC_FILES_URL}/${listId}/api/v2/lists`;
const DOWNLOAD_IMPORT_TEMPLATE = (listId: number | 'all') =>
  `${API_URL}/${listId}/api/import/download_sample`;
const UPDATE_LIST_PROVIDER_CONNS = (listId: number | 'all', createdListId: number) =>
  `${API_URL}/api/lists/${createdListId}/allowed_provider_connections`;

const CHECK_MULTILIST_SUPPORT = `${API_URL}/all/api/accounts/0/no_multilist`;

const EXPORT_SUP_EXPORTS_TO_CSV_URL = `${STATIC_FILES_URL}/all/api/export`;
const EXPORT_SUP_IMPORTS_TO_CSV_URL = `${STATIC_FILES_URL}/all/api/import`;

const GET_DOMAINS_EXPORT_URL = (list_id: number | 'all') =>
  `${STATIC_FILES_URL}/${list_id}/api/lists`;

export const checkMultilistSupport = async () =>
  axios.get(CHECK_MULTILIST_SUPPORT).then(({ data }) => data);

export const getSidebarLists = async () =>
  axios
    .get<FapiResponse<List[]>>(GET_LISTS_URL, {
      params: {
        sort: 'name',
        order: 'asc',
        type: 'sending',
        archive: false,
      },
    })
    .then(({ data }) => ({
      ...data,
      payload: data.payload.map((list) => ({
        ...list,
        name: list.default ? `${list.name} (Default)` : list.name,
      })),
    }));

export const getListsV2 = (listId: List['id'] | 'all', filters?: ListFilters & SearchParams) =>
  axios
    .get<FapiResponse<List[]>>(GET_LISTS_URL_V2(listId), {
      params: {
        ...filters,
        limit: filters?.limit || 100,
        virtual_fields: 'last_incomplete_suppression_import_status_title,suppress_in_list_ids',
        search: undefined,
        sort: filters?.sort || 'created',
        free_search: filters?.search,
        type: filters?.type === ListShowType.ALL ? undefined : filters?.type,
        archive:
          filters?.archive === ListShowArchivedType.INCLUDE
            ? undefined
            : filters?.archive === ListShowArchivedType.ONLY_ARCHIVED
              ? 1
              : 0,
        hash_type: filters?.hash_type === ListHashTypes.REGULAR ? undefined : filters?.hash_type,
      },
    })
    .then(({ data }) => data);

export const setNewSmsField = async (listId: number | 'all', list_field_id: number) =>
  axios.put<FapiResponse<List>>(`${GET_NEW_LISTS_URL(listId)}/${listId}`, {
    sms_field_id: list_field_id,
  });

export const setNewFrequencyField = async (listId: number | 'all', list_field_id: number) =>
  axios.put<FapiResponse<List>>(`${GET_NEW_LISTS_URL(listId)}/${listId}`, {
    frequency_field_id: list_field_id,
  });

export type ListFilters = {
  account_id?: number;
  name_like?: string;
  name?: string;
  scope?: string;
  free_search?: string;
  exclude_id?: number;
  image_host_base_like?: string;
  tracking_domain_like?: string;
  archive?: ListShowArchivedType;
  deleted?: boolean;
  is_demo?: boolean;
  is_expired?: boolean;
  using_image_or_tracking_domains?: boolean;
  ids?: Array<number | string>;
  type?: string;
  hash_type?: string;
};

export const getListsWithFilters = (
  listId: List['id'] | 'all',
  filters?: ListFilters & SearchParams,
) =>
  axios
    .get<FapiResponse<List[]>>(GET_NEW_LISTS_URL(listId), {
      params: {
        ...filters,
        search: undefined,
        free_search: filters?.search,
        image_host_base_like:
          filters?.image_host_base_like === 'any' ? undefined : filters?.image_host_base_like,
        tracking_domain_like:
          filters?.tracking_domain_like === 'any' ? undefined : filters?.tracking_domain_like,
        sort: filters?.sort || 'id',
        order: filters?.order || 'desc',
      },
    })
    .then(({ data }) => data);

export const DOMAINS_PER_LIST_EXPORT = (filters: ListFilters) => {
  return (listId: number | 'all', exportParams: ExportQueryParams) => {
    const params = {
      limit: exportParams.limit,
      format: exportParams?.format,
      csv_title: exportParams?.csv_title,
      columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
      list_id: listId,
      time_zone: exportParams?.time_zone,
      archive: false,
      type: 'sending',
      select: ['id', 'name', 'image_host_base', 'tracking_domain'],
      image_host_base_like:
        filters?.image_host_base_like === 'any' ? undefined : filters?.image_host_base_like,
      tracking_domain_like:
        filters?.tracking_domain_like === 'any' ? undefined : filters?.tracking_domain_like,
    };

    return buildUrl(GET_DOMAINS_EXPORT_URL(listId), params).toString();
  };
};

export const updateList = async (
  listId: number | 'all',
  updatedList: Partial<List> & { id: Partial<List> },
) => axios.put<FapiResponse<List>>(`${GET_NEW_LISTS_URL(listId)}/${updatedList.id}`, updatedList);

export const newList = async ({ listId, ...list }: ListId & NewListFormFields) => {
  return await axios
    .post<FapiResponse<List>>(POST_NEW_LIST_URL(listId), { ...list })
    .then(({ data }) => data);
};

export const removeList = async ({
  listId,
  listIdToRemove,
}: ListId & { listIdToRemove: number }) => {
  return await axios
    .delete<FapiResponse<List>>(REMOVE_LIST_URL(listId, listIdToRemove))
    .then(({ data }) => data);
};

export const editList = async ({ listId, ...list }: ListId & Partial<List>) => {
  return await axios
    .put<FapiResponse<List>>(EDIT_LIST_URL(listId, list.id), { ...list })
    .then(({ data }) => data);
};

export const copyList = async ({ listId, ...list }: ListId & Partial<List>) => {
  return await axios
    .post<FapiResponse<List>>(POST_NEW_LIST_URL(listId), { ...list })
    .then(({ data }) => data);
};

export const archiveList = async ({
  listId,
  listIdToArchive,
  archive,
}: ListId & ArchiveListParams) => {
  return await axios
    .put<FapiResponse<List>>(EDIT_LIST_URL(listId, listIdToArchive), { archive })
    .then(({ data }) => data);
};

export const downloadImportTemplateCsv = async (listId: number | 'all') => {
  return axios.get<Blob>(DOWNLOAD_IMPORT_TEMPLATE(listId)).then(({ data }) => data);
};

export const LISTS_EXPORT = (listId: number | 'all', exportParams: ExportQueryParams) => {
  const params = {
    limit: exportParams.limit,
    format: exportParams?.format,
    csv_title: exportParams?.csv_title,
    columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
    list_id: listId,
    time_zone: exportParams?.time_zone,
    virtual_fields: 'last_incomplete_suppression_import_status_title,suppress_in_lists',
  };

  return buildUrl(EXPORT_LISTS_CSV_URL(listId), params).toString();
};

export const SUP_EXPORTS_EXPORT = (sortParams: TableSortParams) => {
  return (listId: number | 'all', exportParams: ExportQueryParams) => {
    const params = {
      limit: exportParams.limit,
      format: exportParams?.format,
      csv_title: exportParams?.csv_title,
      columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
      list_id: listId,
      order: sortParams.order,
      sort: sortParams.sort,
    };

    return buildUrl(EXPORT_SUP_EXPORTS_TO_CSV_URL, params).toString();
  };
};

export const SUP_IMPORTS_EXPORT = (sortParams: TableSortParams) => {
  return (listId: number | 'all', exportParams: ExportQueryParams) => {
    const params = {
      limit: exportParams.limit,
      format: exportParams?.format,
      csv_title: exportParams?.csv_title,
      columns: exportParams?.columns ? JSON.stringify(exportParams?.columns) : undefined,
      list_id: listId,
      virtual_fields: 'importer_username,status_desc,is_report_expired',
      order: sortParams.order,
      sort: sortParams.sort,
    };

    return buildUrl(EXPORT_SUP_IMPORTS_TO_CSV_URL, params).toString();
  };
};

export const exportContactData = async (listId: number | 'all', email: string) =>
  axios
    .get<Blob>(`${GET_NEW_LISTS_URL(listId)}/test`, {
      params: {
        email,
      },
    })
    .then(({ data }) => data);

export const updateListProviderConns = async (
  listId: number | 'all',
  createdListId: number,
  allowed_provider_connection_ids: number[] | null,
) =>
  axios.put<FapiResponse<List>>(`${UPDATE_LIST_PROVIDER_CONNS(listId, createdListId)}`, {
    allowed_provider_connection_ids,
  });
