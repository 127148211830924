import { UseQueryResult } from '@tanstack/react-query';
import { ColumnOrderState, OnChangeFn } from '@tanstack/react-table';
import { TableColumn } from '@ui/table';
import { getColumnId, getDefaultColumnWIdth, getHeaderColumnSizes } from '@utils/table';
import { indexOf } from 'lodash';
import { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';
import { persist } from 'zustand/middleware';

interface StoredDataProps<T, K> {
  resetAt?: number;
  initialColumns?: TableColumn<T>[];
  isLoadingColumns?: boolean;
  columnSizes?: { [k: string]: number };
  columns: TableColumn<T>[];
  tableKey: string;
  query: UseQueryResult<K, unknown>;
}

export interface TableDataState<T, K> extends StoredDataProps<T, K> {
  actions: {
    setColumns: (columns: StoredDataProps<T, K>['columns'], isLoading: boolean) => void;
    setQuery: (columns: StoredDataProps<T, K>['query']) => void;
    hideColumn: (column: TableColumn<T>['accessorField']) => void;
    showColumn: (column: TableColumn<T>['accessorField']) => void;
    showAllColumns: () => void;
    resetToDefault: () => void;

    setColumnOrder: OnChangeFn<ColumnOrderState>;
    updateColumnSizes: (sizes: { [k: string]: number }) => void;
  };
}

export type TableDataStore<T, K> = StoreApi<TableDataState<T, K>>;
const createTableColumnStore = <T, K>(initialProps: StoredDataProps<T, K>) => {
  return createStore<TableDataState<T, K>>()(
    persist(
      (set, get) => ({
        ...initialProps,
        actions: {
          setColumnOrder: (updater) => {
            const oldColumns = get().columns;
            let result: string[] = [];

            if (typeof updater === 'function') {
              const currentState = oldColumns.map((c) => getColumnId(c)?.toString() || '');
              result = updater(currentState);
            }

            if (result) {
              set({
                columns: oldColumns.map((c) => ({
                  ...c,
                  order: indexOf(result, getColumnId(c)?.toString() || ''),
                })),
              });
            }
          },
          setColumns: (columns, isLoading) => {
            const initialColumns = get().initialColumns;
            const oldColumns = get().columns;

            set({
              columns: isLoading
                ? []
                : columns.map((c) => {
                    const column = oldColumns.find((oc) => oc.accessorField === c.accessorField);
                    return {
                      ...c,
                      omit: getColumnOmitState(c, column),
                      order: column?.order,
                    };
                  }),
              isLoadingColumns: isLoading,
              initialColumns: isLoading ? [] : initialColumns?.length ? initialColumns : columns,
            });
          },
          setQuery: (query) => {
            set({ query });
          },
          hideColumn: (columnName) => {
            set({
              columns: get().columns.map((c) =>
                [c.id, c.accessorField].includes(columnName) ? { ...c, omit: true } : c,
              ),
            });
          },
          showColumn: (columnName) => {
            set({
              columns: get().columns.map((c) =>
                [c.id, c.accessorField].includes(columnName) ? { ...c, omit: false } : c,
              ),
            });
          },
          showAllColumns: () => {
            set({ columns: get().columns.map((c) => ({ ...c, omit: false })) });
          },
          resetToDefault: () => {
            set({
              // We have to mark latest reset to make sure we rerendered our "performant" way of calculating col sizes on table level
              resetAt: Date.now(),
              columns: get().initialColumns?.map((c, idx) => ({
                ...c,
                omit: c.omit,
                order: idx,
              })),
              columnSizes: get().initialColumns?.reduce(
                (acc, col) => {
                  const width = getDefaultColumnWIdth(col);

                  acc = {
                    ...acc,
                    ...getHeaderColumnSizes(getColumnId(col)?.toString() || '', width, width),
                  };

                  return acc;
                },
                {} as { [k: string]: number },
              ),
            });
          },

          updateColumnSizes: (sizes) => set({ columnSizes: sizes }),
        },
      }),
      {
        name: initialProps?.tableKey || 'index',
        partialize: (state) => ({
          columns: state.columns.map((c) => ({
            accessorField: c.accessorField,
            omit: c.omit,
            order: c.order,
          })),
          columnSizes: state.columnSizes,
          tableKey: state.tableKey,
        }),
        merge: (persistedState, currentState) => {
          const persistedColumnsMap = new Map(
            (persistedState as { columns: TableColumn<T>[] })?.columns.map((pc) => [
              pc.accessorField,
              pc,
            ]),
          );

          return {
            ...currentState,
            columnSizes: (persistedState as { columnSizes?: { [k: string]: number } })?.columnSizes,
            columns: currentState.columns.map((c) => {
              const persistedColumn = persistedColumnsMap.get(c.accessorField);
              return {
                ...c,
                omit: persistedColumn?.omit,
                order: persistedColumn?.order,
              };
            }),
          };
        },
      },
    ),
  );
};

const getColumnOmitState = <T,>(column: TableColumn<T>, oldColumn: TableColumn<T> | undefined) => {
  // TODO Think how to work with state without hideFromTable, it doesn't make sense
  if (column.hideFromTableHidingComponent) {
    return true;
  }

  // always show columns which have "dynamic" omit state
  if (oldColumn?.omit && column.omit === false) {
    return false;
  }

  return oldColumn?.omit ?? column?.omit;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableDataContext = createContext<TableDataStore<any, any> | null>(null);

export const TableDataProvider = <T, K>({
  children,
  ...props
}: PropsWithChildren<StoredDataProps<T, K>>) => {
  const storeRef = useRef<ReturnType<typeof createTableColumnStore<T, K>>>(null);
  if (!storeRef.current) {
    storeRef.current = createTableColumnStore<T, K>(props);
  }

  useEffect(() => {
    storeRef.current?.getState().actions.setColumns(props.columns, !!props.isLoadingColumns);
  }, [props.columns, props.isLoadingColumns]);

  useEffect(() => {
    storeRef.current?.getState().actions.setQuery(props.query);
  }, [props.query]);

  return <TableDataContext.Provider value={storeRef.current}>{children}</TableDataContext.Provider>;
};

export const useTableDataContext = <T, U, K>(selector: (state: TableDataState<T, K>) => U) => {
  const store = useContext(TableDataContext) as TableDataStore<T, K>;
  if (!store) {
    throw new Error('useTableColumnContext must be used within TableColumnProvider');
  }
  return useStore(store, selector);
};
