import { useCallback, useEffect, useState } from 'react';
import {
  useAuthTokensActions,
  usePreviousRefreshToken,
  useRefreshToken,
  useRememberMe,
} from '@module/auth/store/authTokensStore';
import { useWrappedTo } from '@routing/to';
import { useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '@utils/routes';
import { Navigate, useLocation, useNavigate } from 'react-router';

import { LayoutSplashScreen } from '../../../../_metronic/layout/core';

import { refreshToken as refreshTokenRequest } from '@module/auth/api';
import { usePostHog } from 'posthog-js/react';
import config from '../../../../app-config';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { logout, restorePreviousTokens } = useAuthTokensActions();
  const previousRefreshToken = usePreviousRefreshToken();
  const navigate = useNavigate();
  const to = useWrappedTo();

  return useCallback(() => {
    if (previousRefreshToken) {
      restorePreviousTokens();
      navigate(ROUTES.AUTH.LOGOUT_REDIRECT, {
        state: { to: to(ROUTES.CAMPAIGNS.OVERVIEW), refresh: true },
      });
    } else {
      logout();
      navigate(ROUTES.AUTH.LOGOUT_REDIRECT, { state: { to: ROUTES.AUTH.LOGIN, refresh: false } });
    }
    queryClient.clear();
  }, [logout, navigate, previousRefreshToken, queryClient, restorePreviousTokens, to]);
};

export const Logout = () => {
  const { state } = useLocation();
  const [loggedOut, setLoggedOut] = useState(false);

  const refreshToken = useRefreshToken();
  const rememberMe = useRememberMe();
  const posthog = usePostHog();

  const { setTokens } = useAuthTokensActions();

  const handleLogout = () => {
    if (state?.refresh) {
      refreshTokenRequest(refreshToken, rememberMe).then(({ data }) => {
        setTokens(data.access_token, data.refresh_token, posthog, rememberMe);
        setLoggedOut(true);
      });
    } else {
      setLoggedOut(true);
    }
  };

  if (loggedOut) {
    return <Navigate to={state?.to || ROUTES.AUTH.LOGIN} />;
  }

  return (
    <>
      <iframe
        src={`${config.ongageApiUrl}/logout`}
        width={0}
        height={0}
        sandbox="allow-same-origin allow-scripts" // prevent redirecting
        onLoad={handleLogout}
      />
      <LayoutSplashScreen />
    </>
  );
};

export const LogoutPage = () => {
  const logout = useLogout();

  useEffect(() => logout(), [logout]);

  return <></>;
};
