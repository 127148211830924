import { FC, useEffect } from 'react';
import { toastError } from '@ui/Toasts';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router';
import { Slide, ToastContainer } from 'react-toastify';

import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { useVerifyNewAccountEmail } from '../queries';

export const FirstTimeLogin: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { mutate: verifyNewAccEmail } = useVerifyNewAccountEmail();

  useEffect(() => {
    if (token) {
      verifyNewAccEmail(token, {
        onError: (err) => {
          toastError({
            title: 'First time login.',
            text:
              err instanceof AxiosError
                ? err.response?.data?.payload?.message
                : 'Something went wrong. Please check the link and try again.',
            autoClose: false,
          });
        },
      });
    }
  }, [token, verifyNewAccEmail]);

  return (
    <>
      <ToastContainer
        transition={Slide}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position="top-right"
        containerId="top"
        closeOnClick
        hideProgressBar
      />
      <LayoutSplashScreen />
    </>
  );
};
