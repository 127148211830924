import { lazy } from 'react';
import { AuthLayout } from '@module/auth/components/AuthLayout';
import { FirstTimeLogin } from '@module/auth/components/FirstTimeLogin';
import { ForgotPasswordLayout } from '@module/auth/components/ForgotPasswordLayout';
import { ErrorsPage } from '@module/errors/ErrorsPage';
import { AlreadyAuthenticatedUserGuard } from '@pages/auth/guards/AlreadyAuthenticatedUserGuard';
import LoginPage from '@pages/auth/login/LoginPage';
import { Logout, LogoutPage } from '@pages/auth/logout/Logout';
import { SuspensedWrapper } from '@routing/SuspensedWrapper';
import { ROUTES } from '@utils/routes';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router';

import { PrivateRoutes } from './PrivateRoutes';
import { useWrappedTo } from './to';
import RootPage from '../pages/RootPage';

const CampaignsNavigate = () => {
  const to = useWrappedTo();
  return <Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />;
};

const PricingPage = lazy(() => import('@pages/pricing/PricingPage'));
const ForgotPasswordPage = lazy(() => import('@pages/auth/forgot-password/ForgotPasswordPage'));
const PasswordResetCompletePage = lazy(
  () => import('@pages/auth/forgot-password/PasswordResetComplete'),
);
const SentLinkMessagePage = lazy(() => import('@pages/auth/forgot-password/SentLinkMessagePage'));
const SetNewPasswordPage = lazy(() => import('@pages/auth/forgot-password/SetNewPasswordPage'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootPage />}>
      <Route path="error">
        <Route path="*" element={<ErrorsPage />} />
      </Route>

      <Route path={ROUTES.AUTH.FIRST_LOGIN} element={<FirstTimeLogin />} />

      <Route path="/">
        <Route path="*" element={<PrivateRoutes />} />
      </Route>

      <Route
        path={ROUTES.AUTH.LOGIN}
        element={
          <AlreadyAuthenticatedUserGuard>
            <AuthLayout />
          </AlreadyAuthenticatedUserGuard>
        }
      >
        <Route
          index
          element={
            <SuspensedWrapper>
              <LoginPage />
            </SuspensedWrapper>
          }
        />
      </Route>
      <Route
        path={ROUTES.AUTH.LOGOUT}
        element={
          <SuspensedWrapper>
            <LogoutPage />
          </SuspensedWrapper>
        }
      />
      <Route
        path={ROUTES.AUTH.LOGOUT_REDIRECT}
        element={
          <SuspensedWrapper>
            <Logout />
          </SuspensedWrapper>
        }
      />
      <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPasswordLayout />}>
        <Route
          index
          element={
            <SuspensedWrapper>
              <ForgotPasswordPage />
            </SuspensedWrapper>
          }
        />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD_SENT_LINK}
          element={
            <SuspensedWrapper>
              <SentLinkMessagePage />
            </SuspensedWrapper>
          }
        />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD_RESET}
          element={
            <SuspensedWrapper>
              <SetNewPasswordPage />
            </SuspensedWrapper>
          }
        />
        <Route
          path={ROUTES.AUTH.FORGOT_PASSWORD_RESET_COMPLETE}
          element={
            <SuspensedWrapper>
              <PasswordResetCompletePage />
            </SuspensedWrapper>
          }
        />
      </Route>
      <Route
        path={ROUTES.PRICING.INDEX}
        element={
          <SuspensedWrapper>
            <PricingPage />
          </SuspensedWrapper>
        }
      />
      <Route index element={<CampaignsNavigate />} />
    </Route>,
  ),
);
