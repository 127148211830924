import { TableColumn } from '@ui/table';

export const HEADER_SIZE_PATTERN = (id: string) => `--header-${id}-size`;
export const COLUMN_SIZE_PATTERN = (id: string) => `--col-${id}-size`;
export const ACTIONS_COLUMN_ID = 'actions';

// We will get column size and header size to use it in table component and reset option
export const getHeaderColumnSizes = (
  id: string,
  headerSize: number,
  columnSize: number,
): { [k: string]: number } => {
  const colSizes: { [key: string]: number } = {};
  colSizes[HEADER_SIZE_PATTERN(id)] = headerSize;
  colSizes[COLUMN_SIZE_PATTERN(id)] = columnSize;

  return colSizes;
};

export const getColumnId = <T>(column: TableColumn<T>) => {
  return column.id || column.accessorField;
};

export const isActionsColumn = <T>(column: TableColumn<T>) =>
  column.name === 'Actions' || column.id === ACTIONS_COLUMN_ID;

export const getDefaultColumnWIdth = <T>(column: TableColumn<T>) => {
  const width = column.width?.replace('px', '') || column.minWidth?.replace('px', '');

  if (isActionsColumn(column)) {
    return Number(width || 45);
  }

  return Number(width || 85);
};

// It's 20 be default, we need more
export const DEFAULT_TANSTACK_MIN_WIDTH = 30;

export const getColumnMinWidth = <T>(column: TableColumn<T>) => {
  const width = getDefaultColumnWIdth(column);
  const minWidth = Number(column.minWidth?.replace('px', '') || 30);

  return width < minWidth ? width : minWidth;
};
