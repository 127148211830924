import { DecodedJWT, TokenUserRoles } from '@module/shared/models/models';
import { ForgotPwdFormFields } from '@pages/auth/forgot-password/ForgotPasswordPage';
import { useIframeMessage } from '@providers/IframeMessageProvider';
import { useWrappedTo } from '@routing/to';
import { useMutation } from '@tanstack/react-query';
import { ROUTES } from '@utils/routes';
import { jwtDecode } from 'jwt-decode';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router';

import { login, loginMFA, requestPassword, updatePassword, verifyNewAccEmail } from './api';
import { useAuthTokensActions } from './store/authTokensStore';
import { useMfaTokenActions } from './store/mfaTokenStore';
import { isAuthModel, isMFAModel } from './utils';

type UseLoginParams = { rememberMe?: boolean } & (
  | { login: string; password: string; account_code?: string }
  | { mfaToken: string; mfaCode: string }
);

export const useLogin = () => {
  const { setTokens } = useAuthTokensActions();
  const { setMfaToken, setQrCode, resetMfa, setMfaSecret } = useMfaTokenActions();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { postMessage } = useIframeMessage();

  const to = useWrappedTo();

  return useMutation({
    mutationFn: async (params: UseLoginParams) => {
      if ('mfaToken' in params) {
        return await loginMFA(params.mfaToken, params.mfaCode, params.rememberMe);
      }

      return await login(params.login, params.password, params.account_code, params.rememberMe);
    },
    onSuccess: ({ data }, params) => {
      if (isMFAModel(data)) {
        setMfaToken(data.mfa_token);
        setMfaSecret(data.mfa_secret);
        if (data.qr_image) setQrCode(data.qr_image);
        return;
      }

      if (isAuthModel(data)) {
        setTokens(data.access_token, data.refresh_token, posthog, params.rememberMe || false);
        resetMfa();
        // check if user is analytics agent (report-viewer) as he has only this page available
        const token: DecodedJWT = jwtDecode(data.access_token);
        token.role === TokenUserRoles.REPORT_VIEWER
          ? navigate(to(ROUTES.ANALYTICS.AGGREGATE))
          : navigate(to(ROUTES.CAMPAIGNS.OVERVIEW));
        return;
      }
    },
  });
};

export const useRequestPassword = () => {
  return useMutation({
    mutationFn: (payload: ForgotPwdFormFields) => requestPassword(payload),
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: (data: { password: string; token: string }) => updatePassword(data),
  });
};

export const useVerifyNewAccountEmail = () => {
  const { setTokens } = useAuthTokensActions();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const to = useWrappedTo();

  return useMutation({
    mutationFn: (token: string) => verifyNewAccEmail(token),
    onSuccess: ({ payload }) => {
      setTokens(payload.access_token, payload.refresh_token, posthog, false);
      navigate(to(ROUTES.SETUP.WELCOME));
    },
  });
};
