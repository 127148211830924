import {
  SupportFormFields,
  SupportUploadFields,
} from '@module/settings/components/account-settings/components/SupportForm';
import {
  addUser,
  editUser,
  getUserAclPrivileges,
  getUserAllowedLists,
  getUserErrors,
  getUserInfo,
  getUsers,
  openSupportTicket,
  removeUser,
  reviveUser,
  uploadSupportFile,
} from '@module/shared/api/user-mgmt';
import { accountsKeys } from '@module/shared/hooks/account-queries';
import { useTableFilters } from '@module/shared/hooks/useTableFilters';
import { SearchParams, UserFormFields } from '@module/shared/models/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useDecodedToken } from '../../auth/store/authTokensStore';
import { useSelectedListId } from '../store/useListStore';

export const usersKeys = {
  users: (listId: number | 'all', searchParams?: SearchParams) =>
    [
      {
        entity: 'users',
        listId,
        ...searchParams,
      },
    ] as const,
  user: (id: number) =>
    [
      {
        entity: 'user',
        id,
      },
    ] as const,
  userErrors: () =>
    [
      {
        entity: 'user',
      },
    ] as const,
  userSupportTicket: () =>
    [
      {
        entity: 'user',
        action: 'open_support',
      },
    ] as const,
  uploadSupportTicketFiles: () =>
    [
      {
        entity: 'user',
        action: 'upload_support_file',
      },
    ] as const,
  userAllowedLists: (id: number) =>
    [
      {
        id,
        entity: 'user',
        action: 'allowed_lists',
      },
    ] as const,
  userACL: () =>
    [
      {
        entity: 'user/acl_privileges',
      },
    ] as const,
};

export const useUsers = () => {
  const listId = useSelectedListId();
  const { filters: searchParams } = useTableFilters();
  return useQuery({
    queryKey: usersKeys.users(listId!, searchParams),
    queryFn: ({ queryKey: [{ listId, ...searchParams }] }) => getUsers(listId, searchParams),
    enabled: !!listId,
  });
};

export const useUserInfo = () => {
  const decodedToken = useDecodedToken();

  return useQuery({
    queryKey: usersKeys.user(decodedToken?.user_id || -1),
    queryFn: ({ queryKey: [{ id }] }) => getUserInfo(id),
    enabled: decodedToken?.user_id !== undefined,
    refetchOnMount: false,
    staleTime: 30_000,
  });
};

export const useEditUser = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: UserFormFields) => editUser({ listId: listId!, ...user }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: usersKeys.users(listId!) });
      queryClient.invalidateQueries({ queryKey: usersKeys.user(res.payload.id) });
    },
  });
};

export const useReviveUser = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: UserFormFields) => reviveUser({ listId: listId!, ...user }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: usersKeys.users(listId!) }),
  });
};

export const useAddUser = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (user: UserFormFields) => addUser({ listId: listId!, ...user }),
    onSuccess: (user) => {
      // We need to disable 'new user' button based on account data (user limit reached)
      queryClient.invalidateQueries({ queryKey: accountsKeys.account(user.payload.account_id) });
      queryClient.invalidateQueries({ queryKey: usersKeys.users(listId!) });
    },
  });
};

export const useRemoveUser = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: number) => removeUser({ listId: listId!, userId }),
    onSuccess: (user) => {
      queryClient.invalidateQueries({ queryKey: accountsKeys.account(user.payload.account_id) });
      queryClient.invalidateQueries({ queryKey: usersKeys.users(listId!) });
    },
  });
};

export const useUserAllowedLists = (id: number) => {
  return useQuery({
    queryKey: usersKeys.userAllowedLists(id),
    queryFn: ({ queryKey: [{ id }] }) => getUserAllowedLists(id),
  });
};

// User support form queries
export const useSupportFormErrors = () => {
  return useQuery({
    queryKey: usersKeys.userErrors(),
    queryFn: getUserErrors,
  });
};

export const useUploadSupportFile = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SupportUploadFields[]) => uploadSupportFile(listId!, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: usersKeys.uploadSupportTicketFiles() }),
  });
};

export const useNewSupportTicket = () => {
  const listId = useSelectedListId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SupportFormFields) => openSupportTicket(listId!, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: usersKeys.userSupportTicket() }),
  });
};

export const useUserACL = () => {
  const decodedToken = useDecodedToken();

  return useQuery({
    queryKey: usersKeys.userACL(),
    queryFn: getUserAclPrivileges,
    enabled: decodedToken?.user_id !== undefined,
    select: (d) => d.payload,
    refetchOnMount: false,
    staleTime: 60_000 * 5,
  });
};
