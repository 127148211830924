import { FC, forwardRef } from 'react';
import { CheckboxField, CheckboxFieldProps } from '@ui/CheckboxField';
import { useField } from 'formik';

type FormikCheckboxFieldProps = Omit<CheckboxFieldProps, 'checked'>;

export const FormikCheckboxField: FC<FormikCheckboxFieldProps> = forwardRef<
  HTMLInputElement,
  FormikCheckboxFieldProps
>((props, ref) => {
  const [field] = useField({
    name: props.name,
    type: 'checkbox',
  });

  return (
    <CheckboxField
      {...field}
      checked={Boolean(field.checked)}
      id={props.id || props.name}
      {...props}
      ref={ref}
    />
  );
});
