import { useMemo } from 'react';
import { Option, useSelectData } from '@module/shared/hooks/useSelectData';
import { debounce } from 'lodash';
import { Col, Dropdown, Spinner } from 'react-bootstrap';

import { DropDownItemAsOption } from './DropdownItemAsOption';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input';
import { DropDownItemAsRadioGroup } from './DropdownItemAsRadioGroup';

type DropDownItemAsSelectProps<T extends { id: string | number; name?: string }> = {
  onSearchChange: (value: string) => void;
  value: ReturnType<typeof useSelectData<T>>['value'];
  onOptionToggle: (option: Option<T>) => void;
  isLoading?: boolean;
  options: ReturnType<typeof useSelectData<T>>['options'];
  isSingle?: boolean;
};
export const DropDownItemAsSelect = <T extends { id: string | number; name?: string }>({
  onSearchChange,
  value,
  onOptionToggle,
  isLoading,
  options,
  isSingle = false,
}: DropDownItemAsSelectProps<T>) => {
  const debounced = useMemo(() => debounce(onSearchChange, 300), [onSearchChange]);

  const handleSearch = (query: string) => {
    debounced(query);
  };

  return (
    <>
      <Dropdown.Item className="p-2">
        <Input
          wrapperClassName="w-100"
          placeholder="Search"
          leftSection={<Icon name="searchLg" />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Dropdown.Item>
      {isLoading && (
        <Dropdown.Item>
          <Col className="text-center">
            <Spinner />
          </Col>
        </Dropdown.Item>
      )}
      <div style={{ maxHeight: 500, overflow: 'auto' }}>
        {!isLoading &&
          options.map((option, index) => {
            return isSingle ? (
              <SingleOption
                key={index}
                option={option}
                value={value}
                onChange={() => onOptionToggle(option)}
              />
            ) : (
              <MultiOption
                key={index}
                option={option}
                value={value}
                onChange={() => onOptionToggle(option)}
              />
            );
          })}
      </div>
    </>
  );
};

const SingleOption = <T extends { id: string | number; name?: string }>({
  option,
  value,
  onChange,
}: {
  option: ReturnType<typeof useSelectData<T>>['options'][0];
  value: ReturnType<typeof useSelectData<T>>['value'];
  onChange: () => void;
}) => {
  return (
    <DropDownItemAsRadioGroup
      option={{ value: option.value?.id.toString() || '', label: option.label }}
      selected={value?.[0]?.value?.id.toString()}
      onChange={onChange}
    />
  );
};

const MultiOption = <T extends { id: string | number; name?: string }>({
  option,
  value,
  onChange,
}: {
  option: ReturnType<typeof useSelectData<T>>['options'][0];
  value: ReturnType<typeof useSelectData<T>>['value'];
  onChange: () => void;
}) => {
  return (
    <DropDownItemAsOption
      label={option.label}
      checked={!!value.find((s) => s.value?.id === option.value?.id)}
      onToggle={onChange}
    />
  );
};
