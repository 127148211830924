import { FC, lazy, LazyExoticComponent, memo, PropsWithChildren, useMemo } from 'react';
import { useAccessToken } from '@module/auth/store/authTokensStore';
import { useSelectedListId } from '@module/list/store/useListStore';
import { useAccountInfo } from '@module/shared/hooks/account-queries';
import { useUserPermissions } from '@module/shared/hooks/useRoutePermissionsCheck';
import { IframePage } from '@pages/IframePage';
import { SuspensedWrapper } from '@routing/SuspensedWrapper';
import { toastError } from '@ui/Toasts';
import { UserPermissions } from '@utils/permissionsCheck';
import { ROUTES } from '@utils/routes';
import { usePostHog } from 'posthog-js/react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router';

import { useWrappedTo } from './to';
import { LayoutSplashScreen } from '../../_metronic/layout/core';
import config from '../../app-config';
import { differenceInCalendarDays, fromUnixTime } from 'date-fns';

// Pages
const MasterLayout = lazy(() => import('../../_metronic/layout/MasterLayout'));
const DashboardPage = lazy(() => import('@pages/list/dashboard/DashboardPage'));
const ImportPage = lazy(() => import('@pages/list/import/ImportPage'));
const ExportPage = lazy(() => import('@pages/list/export/ExportPage'));
const SegmentsPage = lazy(() => import('@pages/list/segments/SegmentsPage'));
const NewSegmentPage = lazy(() => import('@pages/list/segments/NewSegmentPage'));
const IframeEditSegmentPage = lazy(() => import('@pages/list/segments/EditSegmentPage'));
const ContactsManagerPage = lazy(() => import('@pages/list/contacts-manager/ContactsManagerPage'));
const NewContactsSearchPage = lazy(
  () => import('@pages/list/contacts-manager/NewContactsSearchPage'),
);
const ContactSearchReportPage = lazy(
  () => import('@pages/list/contacts-manager/ContactSearchReportPage'),
);
const FormBuilderPage = lazy(() => import('@pages/list/form-builder/FormBuilderPage'));
const NewFormBuilderPage = lazy(() => import('@pages/list/form-builder/NewFormBuilderPage'));
const ConversionPointsPage = lazy(
  () => import('@pages/list/conversion-points/ConversionPointsPage'),
);
const NewConversionPointPage = lazy(
  () => import('@pages/list/conversion-points/NewConversionPointPage'),
);
const EditConversionPointPage = lazy(
  () => import('@pages/list/conversion-points/EditConversionPointPage'),
);
const ListSettingsPage = lazy(() => import('../pages/list/settings/ListSettingsPage'));
const ProfileSettingsPage = lazy(() => import('../pages/settings/ProfileSettingsPage'));
const AccountSpecsPage = lazy(() => import('../pages/settings/AccountSpecsPage'));
const DomainRoutingPage = lazy(() => import('../pages/settings/DomainRoutingPage'));
const AccountSettingsPage = lazy(() => import('../pages/settings/AccountSettingsPage'));
const TrackingImageDomains = lazy(() => import('../pages/settings/TrackingImageDomains'));
const UserManagement = lazy(() => import('../pages/settings/UserManagement'));
const ListManagement = lazy(() => import('../pages/settings/ListManagement'));
const AutomationsAnalyticsPage = lazy(() => import('../pages/marketing-automation/AnalyticsPage'));
const WorkflowNodesAnalyticsPage = lazy(
  () => import('../pages/marketing-automation/WorkflowNodesAnalyticsPage'),
);

// IFrames
const MarketingAutomationPage = lazy(
  () => import('../pages/marketing-automation/MarketingAutomationPage'),
);

const AIToolPage = lazy(() => import('../pages/AI/AIToolPage'));
const AutomationRulesPage = lazy(() => import('@pages/list/automation-rules/AutomationRulesPage'));
const CampaignOverviewPage = lazy(() => import('../pages/campaign/OverviewPage'));
const ABPage = lazy(() => import('../pages/campaign/ABPage'));
const CalendarPage = lazy(() => import('../pages/campaign/CalendarPage'));
const CampaignTransactionalPage = lazy(() => import('../pages/campaign/TransactionalPage'));
const EventsAndTriggersPage = lazy(() => import('../pages/campaign/EventsAndTriggersPage'));
const MyConnectionsPage = lazy(() => import('../pages/vendors/MyConnectionsPage'));
const SMSVendorsPage = lazy(() => import('../pages/vendors/SMSVendorsPage'));
const ESPAndSMTPVendorsPage = lazy(() => import('../pages/vendors/ESPAndSMTPVendorsPage'));
const EditConnectionPage = lazy(() => import('../pages/vendors/EditConnectionPage'));
const EmailMessagesPage = lazy(() => import('../pages/content/EmailMessagesPage'));
const EmailMessagesFormPage = lazy(() => import('../pages/content/EmailMessagesFormPage'));
const SMSMessagesPage = lazy(() => import('../pages/content/SMSMessagesPage'));
const ImageLibraryPage = lazy(() => import('../pages/content/ImageLibraryPage'));
const TemplatesLibraryPage = lazy(() => import('../pages/content/TemplatesLibraryPage'));
const TransactionalPage = lazy(() => import('../pages/analytics/TransactionalPage'));
const OverviewPage = lazy(() => import('../pages/analytics/OverviewPage'));
const AggregatePage = lazy(() => import('../pages/analytics/AggregatePage'));
const EventsPage = lazy(() => import('../pages/analytics/EventsPage'));
const MatrixPage = lazy(() => import('../pages/analytics/MatrixPage'));
const ContactActivityPage = lazy(() => import('../pages/analytics/ContactActivityPage'));
const InsightsPage = lazy(() => import('../pages/analytics/InsightsPage'));
const SplitCampaignsPage = lazy(() => import('../pages/analytics/SplitCampaignsPage'));
const CustomAggregateReportPage = lazy(
  () => import('../pages/analytics/CustomAggregateReportPage'),
);
const EmailValidationPage = lazy(() => import('../pages/email-validation/EmailValidationPage'));
const EmailValidationFormPage = lazy(
  () => import('../pages/email-validation/EmailValidationFormPage'),
);
const IframeEditFormBuilderPage = lazy(
  () => import('@pages/list/form-builder/EditFormBuilderPage'),
);
const IframeCopyFormBuilderPage = lazy(
  () => import('@pages/list/form-builder/CopyFormBuilderPage'),
);
const NopIndexPage = lazy(() => import('../pages/nop/NopIndexPage'));
const SetupWelcome = lazy(() => import('../pages/setup/SetupWelcome'));
const EventFormPage = lazy(() => import('../pages/vendors/EventsFormPage'));
const AutomationRulesFormPage = lazy(() => import('../pages/vendors/AutomationRulesFormPage'));
const ChoosePlanPage = lazy(() => import('@pages/settings/ChoosePlanPage'));

const routes: Array<{
  path: string;
  element: LazyExoticComponent<FC>;
  supportsAllList?: boolean;
  accessiblePermissionKey: keyof UserPermissions;
}> = [
  {
    path: ROUTES.LIST.OVERVIEW,
    element: DashboardPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasDashboardViewPermission',
  },
  {
    path: ROUTES.LIST.IMPORT,
    element: ImportPage,
    accessiblePermissionKey: 'hasListImportViewPermission',
  },
  {
    path: ROUTES.LIST.EXPORT,
    element: ExportPage,
    accessiblePermissionKey: 'hasListExportViewPermission',
  },
  {
    path: ROUTES.LIST.SEGMENTS,
    element: SegmentsPage,
    accessiblePermissionKey: 'hasSegmentsViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_SEGMENTS,
    element: NewSegmentPage,
    accessiblePermissionKey: 'hasNewSegmentsViewPermission',
  },
  {
    path: ROUTES.LIST.EDIT_SEGMENT,
    element: IframeEditSegmentPage,
    accessiblePermissionKey: 'hasEditSegmentViewPermission',
  },
  {
    path: ROUTES.LIST.CONTACTS_MANAGER,
    element: ContactsManagerPage,
    accessiblePermissionKey: 'hasContactsManagerViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_CONTACT_SEARCH,
    element: NewContactsSearchPage,
    accessiblePermissionKey: 'hasNewContactsSearchViewPermission',
  },
  {
    path: ROUTES.LIST.CONTACT_SEARCH_REPORT,
    element: ContactSearchReportPage,
    accessiblePermissionKey: 'hasContactSearchReportViewPermission',
  },
  {
    path: ROUTES.LIST.NEW_CONVERSION_POINTS,
    element: NewConversionPointPage,
    accessiblePermissionKey: 'hasNewConversionPointsViewPermission',
  },
  {
    path: ROUTES.LIST.EDIT_CONVERSION_POINT,
    element: EditConversionPointPage,
    accessiblePermissionKey: 'hasEditConversionPointViewPermission',
  },
  {
    path: ROUTES.LIST.AUTOMATION_RULES,
    element: AutomationRulesPage,
    accessiblePermissionKey: 'hasAutomationRulesViewPermission',
  },
  {
    path: ROUTES.LIST.SETTINGS,
    element: ListSettingsPage,
    accessiblePermissionKey: 'hasListSettingsViewPermission',
  },
  {
    path: ROUTES.AUTOMATION.LIST,
    element: MarketingAutomationPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasMarketingAutomationPermission',
  },
  {
    path: ROUTES.ANALYTICS.ANALYTICS_BY_WORKFLOW,
    element: WorkflowNodesAnalyticsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasMarketingAutomationPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.OVERVIEW,
    element: CampaignOverviewPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasCampaignOverviewViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.A_B,
    element: ABPage,
    accessiblePermissionKey: 'hasCampaignsABViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.CALENDAR,
    element: CalendarPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasCampaignsCalendarViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.TRANSACTIONAL,
    element: CampaignTransactionalPage,
    accessiblePermissionKey: 'hasCampaignsTransactionalViewPermission',
  },
  {
    path: ROUTES.CAMPAIGNS.EVENTS_AND_TRIGGERS,
    element: EventsAndTriggersPage,
    accessiblePermissionKey: 'hasEventsAndTriggersViewPermission',
  },
  {
    path: ROUTES.VENDORS.CONNECTIONS,
    element: MyConnectionsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasConnectionsViewPermission',
  },
  {
    path: ROUTES.VENDORS.SMS,
    element: SMSVendorsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasSMSVendorsViewPermission',
  },
  {
    path: ROUTES.VENDORS.ESP_AND_SMTP,
    element: ESPAndSMTPVendorsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasESPAndSMTPVendorsViewPermission',
  },
  {
    path: ROUTES.VENDORS.EDIT_CONNECTION,
    element: EditConnectionPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasEditConnectionViewPermission',
  },
  {
    path: ROUTES.VENDORS.EVENTS_FORM,
    element: EventFormPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasEditConnectionViewPermission',
  },
  {
    path: ROUTES.VENDORS.AUTOMATION_RULES_FORM,
    element: AutomationRulesFormPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasEditConnectionViewPermission',
  },
  {
    path: ROUTES.CONTENT.EMAILS,
    element: EmailMessagesPage,
    accessiblePermissionKey: 'hasContentEmailsViewPermission',
  },
  {
    path: ROUTES.CONTENT.EMAIL_FORM,
    element: EmailMessagesFormPage,
    accessiblePermissionKey: 'hasContentEmailFormViewPermission',
  },
  {
    path: ROUTES.CONTENT.SMS,
    element: SMSMessagesPage,
    accessiblePermissionKey: 'hasContentSMSViewPermission',
  },
  {
    path: ROUTES.CONTENT.IMAGES,
    element: ImageLibraryPage,
    accessiblePermissionKey: 'hasContentImagesViewPermission',
  },
  {
    path: ROUTES.CONTENT.TEMPLATES,
    element: TemplatesLibraryPage,
    accessiblePermissionKey: 'hasContentTemplatesViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.TRANSACTIONAL,
    element: TransactionalPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsTransactionalViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.OVERVIEW,
    element: OverviewPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsOverviewViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.AGGREGATE,
    element: AggregatePage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsAggregateViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.EVENTS,
    element: EventsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsEventsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.MATRIX,
    element: MatrixPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsMatrixViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.INSIGHTS,
    element: InsightsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsInsightsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.A_B_TEST,
    element: SplitCampaignsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAnalyticsSplitCampaignsViewPermission',
  },
  {
    path: ROUTES.ANALYTICS.AUTOMATION,
    element: AutomationsAnalyticsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasMarketingAutomationPermission',
  },
  {
    path: ROUTES.REPORTS.AGGREGATE_REPORT,
    element: CustomAggregateReportPage,
    accessiblePermissionKey: 'hasAnalyticsAggregateReportViewPermission',
  },
  {
    path: ROUTES.REPORTS.CONTACTS_ACTIVITY,
    element: ContactActivityPage,
    accessiblePermissionKey: 'hasAnalyticsContactsActivityViewPermission',
  },
  {
    path: ROUTES.TOOLS.AI,
    element: AIToolPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAIViewPermission',
  },
  {
    path: ROUTES.TOOLS.VALIDATION,
    element: EmailValidationPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasValidationIndexViewPermission',
  },
  {
    path: ROUTES.TOOLS.VALIDATION_FORM,
    element: EmailValidationFormPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasValidationFormViewPermission',
  },
  {
    path: ROUTES.TOOLS.FORM_BUILDER,
    element: FormBuilderPage,
    accessiblePermissionKey: 'hasFormBuilderPermission', // different routes, same rule
  },
  {
    path: ROUTES.TOOLS.NEW_FORM_BUILDER,
    element: NewFormBuilderPage,
    accessiblePermissionKey: 'hasFormBuilderPermission',
  },
  {
    path: ROUTES.TOOLS.EDIT_FORM_BUILDER,
    element: IframeEditFormBuilderPage,
    accessiblePermissionKey: 'hasFormBuilderPermission',
  },
  {
    path: ROUTES.TOOLS.COPY_FORM_BUILDER,
    element: IframeCopyFormBuilderPage,
    accessiblePermissionKey: 'hasFormBuilderPermission',
  },
  {
    path: ROUTES.TOOLS.CONVERSION_POINTS,
    element: ConversionPointsPage,
    accessiblePermissionKey: 'hasConversionPointsViewPermission',
  },
  {
    path: ROUTES.SETTINGS.PROFILE,
    element: ProfileSettingsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasProfilePermission',
  },
  {
    path: ROUTES.SETTINGS.USAGE,
    element: AccountSpecsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAccountSpecsViewPermission',
  },
  {
    path: ROUTES.SETTINGS.DOMAIN_ROUTING,
    element: DomainRoutingPage,
    accessiblePermissionKey: 'hasDomainRoutingViewPermission',
    supportsAllList: true,
  },
  {
    path: ROUTES.SETTINGS.CONFIGURATIONS,
    element: AccountSettingsPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasAccountSettingsViewPermission',
  },
  {
    path: ROUTES.SETTINGS.TRACKING_IMAGE_DOMAINS,
    element: TrackingImageDomains,
    supportsAllList: true,
    accessiblePermissionKey: 'hasImageTrackingViewPermission',
  },
  {
    path: ROUTES.SETTINGS.USERS,
    element: UserManagement,
    supportsAllList: true,
    accessiblePermissionKey: 'hasUserManagementViewPermission',
  },
  {
    path: ROUTES.SETTINGS.LISTS,
    element: ListManagement,
    supportsAllList: true,
    accessiblePermissionKey: 'hasListManagementViewPermission',
  },
  {
    path: ROUTES.SETTINGS.CHOOSE_PLAN,
    element: ChoosePlanPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasChoosePlanPermission',
  },
  {
    path: ROUTES.NOP.INDEX,
    element: NopIndexPage,
    supportsAllList: true,
    accessiblePermissionKey: 'hasNopIndexViewPermission',
  },
  {
    path: ROUTES.SETUP.WELCOME,
    element: SetupWelcome,
    accessiblePermissionKey: 'hasSetupWelcomeViewPermission',
  },
];

export const PrivateRoutes = memo(() => {
  const accessToken = useAccessToken();
  const to = useWrappedTo();
  const { isLoading, permissions, isError, error, account } = useUserPermissions();

  if (!accessToken) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  if (isError && error?.response?.status === 403) {
    toastError({
      title: 'Authorization Error',
      text: 'Something wrong with your privileges. Please contact support.',
      autoClose: false,
      withCloseButton: false,
    });
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  if (isLoading) {
    return <LayoutSplashScreen />;
  }

  return (
    <Routes>
      <Route
        element={
          <SuspensedWrapper>
            <MasterLayout />
          </SuspensedWrapper>
        }
      >
        <Route path="auth">
          <Route path="*" element={<Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />} />
        </Route>

        {routes.map(({ path, element: Page, supportsAllList, accessiblePermissionKey }, idx) => (
          <Route
            key={`${path}-${idx}`}
            path={path}
            element={
              <AccessibleSupportGuard isAccessible={permissions[accessiblePermissionKey]}>
                <AllListsSupportGuard supportsAllLists={Boolean(supportsAllList)}>
                  <TrialExpiredGuard
                    isSetupCompleted={!!account?.is_setup_wizard_completed}
                    trialExpirationDate={Number(account?.trial_expiration_date)}
                  >
                    <SuspensedWrapper>
                      <Page />
                    </SuspensedWrapper>
                  </TrialExpiredGuard>
                </AllListsSupportGuard>
              </AccessibleSupportGuard>
            }
          />
        ))}

        <Route path="/:listId">
          <Route path="*" element={<IframeFallback />} />
        </Route>
      </Route>
    </Routes>
  );
});

const AllListsSupportGuard: FC<
  PropsWithChildren<{
    supportsAllLists: boolean;
  }>
> = ({ supportsAllLists, children }) => {
  const to = useWrappedTo();
  const { listId } = useParams();

  if (listId === 'all' && !supportsAllLists) {
    return <Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />;
  }

  return <>{children}</>;
};

const AccessibleSupportGuard: FC<
  PropsWithChildren<{
    isAccessible: boolean;
  }>
> = ({ isAccessible, children }) => {
  const to = useWrappedTo();

  if (!isAccessible) {
    return <Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />;
  }

  return <>{children}</>;
};

const TrialExpiredGuard: FC<
  PropsWithChildren<{ trialExpirationDate: number | undefined; isSetupCompleted: boolean }>
> = ({ trialExpirationDate, isSetupCompleted, children }) => {
  const to = useWrappedTo();

  const daysLeft = useMemo(
    () => differenceInCalendarDays(fromUnixTime(Number(trialExpirationDate)), new Date()),
    [trialExpirationDate],
  );

  // setup not completed and trial is expired
  if (!isSetupCompleted && daysLeft <= 0) {
    return <Navigate to={to(ROUTES.SETUP.WELCOME)} />;
  }

  return <>{children}</>;
};

const IframeFallback = () => {
  const listId = useSelectedListId();
  const location = useLocation();

  const posthog = usePostHog();
  const to = useWrappedTo();
  const { data: account } = useAccountInfo();

  const pathname = location.pathname;
  const search = location.search;

  if (pathname.includes('/ma')) {
    if (!account?.settings?.marketing_automation?.enable) {
      return <Navigate to={to(ROUTES.CAMPAIGNS.OVERVIEW)} />;
    }

    const url = config.maUrl;
    const maPathname = pathname.replace(`/${listId}/ma/ma-workflow`, '');

    return (
      <IframePage
        isLegacyAppPage={false}
        src={() =>
          `${url}${maPathname}?list_id=${listId}&posthog_session_id=${posthog.get_session_id()}`
        }
      />
    );
  }

  // path is /:listId/:path, we exclude /:listId and then add search
  const pathWithoutListId = pathname.split('/').slice(2).join('/');
  const src = `/${pathWithoutListId}${search}`;

  return <IframePage key={src} src={src} />;
};
