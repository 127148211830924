import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router';

export interface PaginationFilters {
  limit: number;
  page: number;
  offset: number | undefined;
  order: 'asc' | 'desc';
  sort: string | undefined;
  search: string | undefined;
}

const getFiltersFromSearchParams = <T extends PaginationFilters>(
  searchParams: URLSearchParams,
): T => {
  const parsedPage = parseInt(searchParams.get('page') || '1');
  const page = Number.isInteger(parsedPage) && parsedPage > 0 ? parsedPage : 1;

  const parsedLimit = parseInt(searchParams.get('limit') || '10');
  const limit = Number.isInteger(parsedLimit) && parsedLimit > 0 ? parsedLimit : 10;

  const filters = {
    order: searchParams.get('order') === 'asc' ? 'asc' : 'desc',
    sort: searchParams.get('sort') || undefined,
    search: searchParams.get('search') || undefined,
  } as PaginationFilters;

  filters.offset = (page - 1) * limit;

  searchParams.forEach((value, key) => {
    if (!(key in filters)) {
      filters[key] = value;
    }
    try {
      filters[key] = JSON.parse(value);
    } catch (e) {
      console.error(e);
      filters[key] = value;
    }
  });

  filters.limit = limit;
  filters.page = page;

  return filters as T;
};

export const useTableFilters = <T>() => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  type F = T & PaginationFilters;
  const filters = useMemo(() => getFiltersFromSearchParams<F>(searchParams), [searchParams]);

  const updateFilter = useMemo(
    () =>
      <K extends keyof Omit<F, 'offset'>>(
        params: Array<{ key: K; value: (typeof filters)[K] }>,
        preservePagination = false,
      ): void => {
        const prevSearchParams = new URLSearchParams(searchParams);
        params.forEach(({ key, value }) => {
          const typedKey = key as string;
          if (!value) {
            prevSearchParams.has(typedKey) && prevSearchParams.delete(typedKey);
          } else {
            prevSearchParams.set(typedKey, JSON.stringify(value));
          }
        });

        if (!preservePagination) {
          prevSearchParams.set('page', '1');
        }

        // Append the current hash to the URL
        navigate(`${location.pathname}?${prevSearchParams.toString()}${location.hash}`);
      },
    [searchParams, location, navigate],
  );

  const clearFilters = useCallback(() => {
    // Append the current hash to the URL
    navigate(`${location.pathname}${location.hash}`);
  }, [location, navigate]);

  return {
    filters,
    updateFilter,
    clearFilters,
  };
};
