import { useState, MouseEvent, KeyboardEvent } from 'react';

export const usePasswordToggle = () => {
  const [visiblePasswords, setVisiblePasswords] = useState<string[]>([]);

  const togglePasswordVisibility = (event: MouseEvent | KeyboardEvent) => {
    // If it's a keyboard event, only toggle on 'Enter' or 'Space'
    if ('key' in event) {
      if (event.key !== 'Enter' && event.key !== ' ') {
        return; // ignore all other keys, e.g., Tab
      }
    }
    event.preventDefault();
    const id = (<HTMLElement>event.currentTarget).id;
    setVisiblePasswords((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const isPasswordVisible = (id: string) => visiblePasswords.includes(id);

  return {
    isPasswordVisible,
    togglePasswordVisibility,
  };
};
